import styled, { css } from "styled-components";

export const Header = styled.header`
  padding-left: 24px;

  h1 {
    margin-top: 14px;
    margin-bottom: -10px;
    font-size: 24px;
    color: #666666;
  }
`;

export const NavWrapper = styled.main`
  padding-left: 24px;
  margin-right: 24px;
  margin-top: 8px;
  padding-left: 24px;

  nav {
    display: flex;

    a {
      display: block;
      background: #f5f5f5;
      padding: 4px 8px;
      color: #999999;
      border-radius: 8px 8px 0 0;
      transition: all ease 0.2s;

      &.current {
        background: #ab92bf;
        color: #ffffff;
      }

      &:hover {
        filter: brightness(0.8);
      }
    }
  }

  header {
    display: flex;
    align-items: center;
    margin-top: 14px;
    padding-left: 22px;

    .status {
      margin-left: 12px;
    }

    .type {
      margin-right: 12px;
    }

    input {
      width: 160px;
    }

    .buttonsWrapper {
      margin-left: auto;
      button:last-child {
        margin-left: 8px;
        margin-right: 8px;
      }
    }
  }

  > div {
    border: 1px solid #ebebeb;
    border-radius: 0 12px 12px 12px;
  }
`;

export const PaginationWrapper = styled.div`
  margin: 24px;
  padding-bottom: 24px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > button:last-child {
    width: 200px;
    height: 35px;

    margin-left: auto;
    background: #f8ed55;
    color: #572580;
    border: none;

    border-radius: 24px;

    transition: all ease 0.2s;

    &:hover {
      filter: brightness(0.8);
    }
  }
`;

export const Footer = styled.footer`
  display: flex;

  > div {
    display: flex;
    padding: 0 15px;
    width: 100%;
    justify-content: space-between;
  }

  .document-button {
    background: #572580;
  }

  .wrapper-consult-buttons {
    display: flex;
  }

  .wrapper-consult-buttons-return {
    display: flex;
    justify-content: end !important;
    width: 100%;
  }

  button:hover {
    filter: brightness(0.8);
  }

  button {
    display: flex;
    flex-direction: line;
    align-items: center;
    justify-content: center;
    font-family: var(--font-display);
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    padding: 16px 24px;
    border-radius: 126px;
    transition: all 0.5s;

    cursor: pointer;

    img {
      margin-left: 8px;
    }
  }

  .document-button {
    border-radius: 24px;
    background-color: #572580;
    color: white;
    border: #572580;
  }

  .button {
    background-color: #f8ed55;
    color: #572580;
    border: #f8ed55;
  }

  button:first-child {
    margin-right: 24px;
  }

  .button-danger {
    background-color: transparent;
    border: none;
    color: #572580;
  }

  button:disabled {
    background-color: #c9c3d0;
    color: #572580;
    border: none;
    cursor: not-allowed;
  }
`;

interface ContentArticleProps {
  open?: boolean;
  fitContent?: boolean;
  twoColumns?: boolean;
}

export const FlexDetailsItems = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
  }
`;

export const GridDetailsItems = styled.div`
  display: grid;
  grid-template-columns: 1fr 90px;

  dl,
  dt,
  dd {
    margin: 0;
    padding: 0;
    color: var(--color-gray-text);
  }

  dt {
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
  }

  dd {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    margin-top: 8px;

    &:last-child {
      justify-self: center;
    }
  }
  @media (min-width: 1321px) {
    dt {
      font-size: 14px;
    }
  }
`;

export const GridPaymentItems = styled.div`
  &.payment-grid {
    margin-top: -7px;
  }

  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 8px;

  dl {
    margin-top: 16px;
    margin-bottom: 0;
    max-width: 175px;
    dd {
      word-break: break-word;
    }
  }

  @media (min-width: 1321px) {
    dl {
      dt {
        font-size: 14px;
      }
    }
  }
`;

export const BackButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  color: #572580;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
`;

export const Centering = styled.div`
  margin: 0 auto;
  max-width: 1800px;
  width: 100%;
`;

export const ContainerRevision = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  flex-wrap: wrap;
  gap: 1.1rem;
  padding: 1rem 1.5rem;

  padding-bottom: 0;
`;

export const ContainerUnity = styled.div`
  padding: 0 1.5rem;
`;

export const BorderSeparate = styled.div`
  margin: 1.125rem 1.5rem;
  border-bottom: 1px solid #ebebeb;
`;

export const LoadingAttendance = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  margin-top: 150px;

  p {
    font-family: var(--font-display);
    font-style: normal;
    font-weight: normal;
    font-size: 1.25rem;
    line-height: 1.75rem;
    color: var(--color-purple);
    text-align: center;
  }

  img {
    width: 4rem;
    margin: 0 auto;
  }
`;

export const ContentArticle = styled.article<ContentArticleProps>`
  flex: 1;

  &.itens-info {
    flex: 2;
  }

  ${(props) =>
    props?.open &&
    css`
      section:first-child {
        height: 100%;
      }
    `};

  ${(props) =>
    props?.fitContent &&
    css`
      width: fit-content;
    `};

  ${(props) =>
    props?.twoColumns &&
    css`
      grid-column: span 2;
    `};

  @media screen and (max-width: 768px) {
    max-width: 70vw;
  }

  @media (min-width: 1321px) {
    dl {
      dt {
        font-size: 14px;
      }
    }
  }
`;

export const TitleUnity = styled.p`
  font-family: var(--font-display);
  font-style: normal;
  font-weight: normal;
  font-size: 1.25rem;
  line-height: 1.75rem;

  letter-spacing: 0.5px;

  color: var(--color-purple);
`;

export const ContainerUnityForm = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  div:first-child {
    flex: 0 0 60%;
  }

  margin-bottom: 1.125rem;
`;

export const TitleFinalValue = styled.p`
  &,
  strong {
    margin: 0;
    padding: 0;
    font-family: var(--font-display);
  }

  font-style: normal;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 500;
  color: #999999;

  strong,
  span {
    color: var(--color-purple);
    font-weight: 600;
  }

  strong {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
`;

export const ContainerButtonRevision = styled.div`
  margin-top: 1rem;
  text-align: right;
  button {
    padding: 8px 36px !important;
  }
`;

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 4rem;

  img {
    width: 4rem;
  }
`;

export const AttendancesContainer = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  gap: 1.5rem;
  border-radius: 16px;
  width: fit-content;
  flex-wrap: wrap;
  margin-top: 24px;
  width: 100%;
`;

// export const ContentContainer = styled.div`
//   display: flex;
//   gap: 1rem;
//   flex-wrap: wrap;

//   @media (min-width: 1321px) {
//     dl {
//       dt {
//         font-size: 14px;
//       }
//     }
//   }
// `;


export const ContentContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;

  /* Garantir que os itens sejam do mesmo tamanho em colunas */
  & > article {
    flex: 1 1 calc(50% - 1rem); /* Ajuste para duas colunas */
  }

  /* Ajuste para telas menores */
  @media (max-width: 768px) {
    & > article {
      flex: 1 1 100%; /* Uma coluna em telas pequenas */
    }
  }

  /* Ajuste para telas maiores */
  @media (min-width: 1024px) {
    & > article {
      flex: 1 1 calc(33.333% - 1rem); /* Três colunas em telas grandes */
    }
  }
`;
