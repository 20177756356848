import { CardType as CardTypeSelectProps } from "Pages/Orders/CreateOrder/components/Patient/components/CardTypeSelect/types"
import { useEffect, useState } from "react";
import { CardTypeSelect } from "Pages/Orders/CreateOrder/components/Patient/components/CardTypeSelect";
import { Field, useFormikContext } from "formik";
import Input from "Components/Form/Input";
import { CardFlagSelect } from "Pages/Orders/CreateOrder/components/Patient/components/CardFlagSelect";
import { CardFlagType } from "Pages/Orders/CreateOrder/components/Patient/components/CardFlagSelect/types";
import LoadingPurple from "Assets/Images/loading-purple.gif";
import { CreditCardInstallment, PaymentOptionType } from "Pages/Orders/CreateOrder/components/PaymentTypes/types";
import { useBudgetOrderLocalStorage } from "Stores/budget-order";

import InputCurrencyControlled from "Components/Form/Controlled/InputCurrencyControlled";
import { calculateAmount } from "Pages/Orders/CreateOrder/components/functions";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { CardTypeProps, ObjectsType } from "./types"
import { SelectPlots } from "../../../SelectPlots";


export const CardType = ({
  values,
  cardFlags,
  loadingCardBanners,
  loadingInstallments,
  fetchInstallments,
  cardInstallments,
  currentIndexPayment
}: CardTypeProps) => {

  const { order, setOrder, currentPaymentType } = useBudgetOrderLocalStorage();

  const { setFieldValue, setFieldError, errors, setErrors } = useFormikContext()

  const options: CardTypeSelectProps[] = [
    {
      id: 0,
      label: 'Selecionar',
    },
    {
      id: 1,
      label: 'Débito',
    },
    {
      id: 2,
      label: 'Crédito',
    },
  ];

  const [objects, setObjects] = useState<ObjectsType>({
    cardType: {
      id: 0,
      label: 'Selecionar'
    },
    cardFlag: {
      id: 0,
      card_banner: 'Selecionar',
    }
  });

  const [showInstallments, setShowInstallments] = useState<boolean>(false)

  useEffect(() => {
    if (cardFlags.length > 0) {
      const foundCardFlag = cardFlags.find((a) => a.id === values.card.flag);
      const foundCardType = options.find((a) => a.id === values.card.type);
      if (foundCardType?.id === 2) {
        fetchInstallments(values.card.total)
      }

      setObjects((prevState) => ({
        ...prevState,
        cardFlag: foundCardFlag ?? prevState.cardFlag,
        cardType: foundCardType ?? prevState.cardType,
      }));

      if(values.card.type === 2) setShowInstallments(true)
    }
  }, [cardFlags]);

  useEffect(() => {

    if (values.card.received) {
      const clonnedPaymentInfo = [...order.payment_info];
      clonnedPaymentInfo[currentIndexPayment] = {
        ...order.payment_info[currentIndexPayment],
        card: {
          ...values.card,
          received: values.card.received
        }
      }
      setDataStorage(clonnedPaymentInfo)
    }
  }, [values.card.received])

  const handleChangeCardType = (value: CardTypeSelectProps) => {
    setObjects({
      ...objects,
      cardType: value
    });
    setFieldValue('card.type', value.id);

    const total = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);
    
    const amountVerification = calculateAmount(currentPaymentType, order.payment_info, total);

    if (((value.id === 2 && amountVerification.isLowerTotal) || (value.id === 2 && amountVerification.sameValue)) && values.card.received) {
      setShowInstallments(true)
      fetchInstallments(values.card.received)
    }

    if (!amountVerification.isLowerTotal) setShowInstallments(false)


    const clonnedPaymentInfo = [...order.payment_info];

    clonnedPaymentInfo[currentIndexPayment] = {
      ...order.payment_info[currentIndexPayment],
      card: {
        ...values.card,
        type: value.id
      }
    }

    setDataStorage(clonnedPaymentInfo)
  }

  const handleChangeCardFlag = (value: CardFlagType) => {
    setObjects({
      ...objects,
      cardFlag: value
    });
    setFieldValue('card.flag', value.id);

    const clonnedPaymentInfo = [...order.payment_info];

    clonnedPaymentInfo[currentIndexPayment] = {
      ...order.payment_info[currentIndexPayment],
      card: {
        ...values.card,
        flag: value.id
      }
    }

    setDataStorage(clonnedPaymentInfo)
  }

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;
    const clonnedPaymentInfo = [...order.payment_info];

    setFieldValue(name, value);

    clonnedPaymentInfo[currentIndexPayment] = {
      ...order.payment_info[currentIndexPayment],
      card: {
        ...values.card,
        nsu: value
      }
    }

    setDataStorage(clonnedPaymentInfo)
  }

  const handleSelectPlot = (selectedPlot: CreditCardInstallment) => {
    setFieldValue('card.selectedInstallment', selectedPlot.number_installments);

    const clonnedPaymentInfo = [...order.payment_info];
    clonnedPaymentInfo[currentIndexPayment] = {
      ...order.payment_info[currentIndexPayment],
      card: {
        ...values.card,
        selectedInstallment: selectedPlot.number_installments,
        addition: selectedPlot.addition,
      }
    }

    setDataStorage(clonnedPaymentInfo)
  }

  const setDataStorage = (payment_info: PaymentOptionType[]) => setOrder({ ...order, payment_info });

  const formatError = (name: string): string | boolean => {
    // Divide o nome em partes para acessar valores aninhados (ex: 'card.type' -> ['card', 'type'])
    const nameParts = name.split('.');

    // Função auxiliar para acessar valores aninhados em um objeto
    const getNestedValue = (obj: any, keys: string[]): any =>
      keys.reduce((acc, key) => (acc && typeof acc === 'object' ? acc[key] : undefined), obj);

    // Verifica e retorna o erro formatado ou `false` se não houver erro
    if (errors && typeof errors === 'object') {
      const nestedError = getNestedValue(errors, nameParts);
      return nestedError || false;
    }

    return false;
  };

  const handleReceivedBlur = () => {
    if (values.card.received) {
      const total = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);

      const amountVerification = calculateAmount(currentPaymentType, order.payment_info, total);

      if (amountVerification.isLowerTotal || amountVerification.sameValue) {
        setShowInstallments(true)
        setFieldError('card.received', '');

        if (values.card.type === 2) {
          fetchInstallments(values.card.received)
        }

        const updatedErrors: any = { ...errors };
        delete updatedErrors?.card;
        return setErrors(updatedErrors);
      }

      if (!amountVerification.isLowerTotal) {
        setShowInstallments(false)
        return setFieldError('card.received', 'Valor recebido é maior que o total')
      }

      if (values.card.received && values.card.type === 2) {
        fetchInstallments(values.card.received)
      }
    }
  }

  return (
    <>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', }}>
        <div style={{ width: '100%' }}>
          <CardTypeSelect
            id='card.type'
            options={options}
            placeholder="Selecionar Tipo"
            loading={false}
            onChange={(value) => handleChangeCardType(value)}
            value={objects.cardType}
            error={formatError('card.type')}
            label="Tipo de Cartão"
          />
        </div>
      </div>
      <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', paddingTop: 10, gap: 10 }}>
        <div style={{ width: '33%' }}>
          <Field
            name="card.received"
            id="card.received"
            type="number"
            label="Valor"
            placeholder="Valor"
            value={values.card.received}
            component={InputCurrencyControlled}
            error={formatError('card.received')}
            onBlur={() => handleReceivedBlur()}
            onChange
          />
        </div>
        <div style={{ width: '33%' }}>
          <Field
            name="card.nsu"
            id="card.nsu"
            type="number"
            label="NSU da Transação"
            placeholder="NSU da Transação"
            value={values.card.nsu}
            component={Input}
            onChange={handleChange}
            error={formatError('card.nsu')}
          />
        </div>
        <div style={{ width: '33%' }}>
          <CardFlagSelect
            id='card.banner'
            options={cardFlags}
            placeholder="Selecionar Bandeira"
            loading={loadingCardBanners}
            onChange={(value) => handleChangeCardFlag(value)}
            value={objects.cardFlag}
            error={formatError('card.flag')}
            label="Bandeira do Cartão"
          />
        </div>
      </div>
      {objects.cardType.id === 2 ?
        <>
          {loadingInstallments ? (
            <div className="xs-12">
              <div className="text-center search-home-loading container-installments-loading">
                <img
                  src={LoadingPurple}
                  alt="Labi Saúde - Carregando"
                  loading="lazy"
                  className="loading-gif"
                />
              </div>
            </div>
          ) : <>
            <div style={{ width: '100%', display: 'flex', paddingTop: 10, gap: 10, flexDirection: 'column' }}>
              {showInstallments ?
                <>
                  <span style={{ fontSize: '14px', color: 'rgba(102, 102, 102, 1)', fontWeight: 600 }}>Em quantas vezes?</span>
                  <div className="xs-12">
                    {cardInstallments.length > 0 && (
                      <div className="select-item-list select-item-plots">
                        {cardInstallments.map((plot: CreditCardInstallment) => (
                          <SelectPlots
                            key={plot.number_installments}
                            selected={values.card.selectedInstallment === plot.number_installments}
                            onClick={() => handleSelectPlot(plot)}
                            {...plot}
                          />
                        ))}
                      </div>
                    )}
                  </div> </> : <></>}
            </div>
          </>}
        </>
        : <></>}
    </>
  )
}