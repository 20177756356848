import styled from "styled-components";

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%; // Para preencher todo o card se necessário
  padding: 16px;

  .header {
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 1rem;
    color: #572580; // Ajuste a cor se necessário
    font-weight: bold;
  }

  .details {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 8px;
    font-size: 0.875rem;
    color: #333;

    .label {
      font-weight: bold;
      margin-right: 4px;
      margin-top: 0;
      margin-bottom: 0px;
      font-size: 16px;
    }
  }

  .title {
    line-height: 16px;
    font-size: 16px;
    font-weight: 600;
    color: rgba(87, 37, 128, 1);

  }

  .subtitle {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(102, 102, 102, 1);
  }

   .button-container {
    display: flex;
    justify-content: flex-end;
  }

  p {
    font-size: 14px;
  }

`;
