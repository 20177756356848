import Select from "react-select";
import { CardType, CardTypeSelectProps } from "./types";

export const CardTypeSelect = ({
  id,
  options,
  value,
  loading,
  onChange,
  placeholder,
  error,
  label
}: CardTypeSelectProps) => {

  const getValue = (options: CardType[], value: string) => {
    return options.filter((o) => value === o.label);
  }

  return (
    <div className={`
      field-holder 
      ${error ? "field-holder-invalid" : ""}
      `}>
      <label
        htmlFor={id}
        className='text-gray text-semibold _purple'
      >
        {label}
      </label>
      <Select
        menuPosition="fixed"
        classNamePrefix="react-select"
        className="react-select input _opacity-gray"
        id={id}
        value={getValue(options, value.label)}
        onChange={(selectedOption) => {
          selectedOption ? onChange(selectedOption) : null
        }}
        isLoading={loading}
        options={options}
        placeholder={placeholder}
        isSearchable
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        getOptionValue={(option) => option.label}
        getOptionLabel={(option) => option.label}
      />
      {error !== "" && (
        <span
          className={`error-message ${!label ? "error-without-label" : ""}`}
        >
          {error}
        </span>
      )}
    </div>
  )

}

