import Axios from "axios";
import { BudgetProps, ListBudget } from "Dtos/IBudget";

import API from "Services/Api";

import { createToast } from "Utils/toastFunc";

import { FormatterData, FormatterBudget } from "../Formatters/Budget";
import {
  BudgetCombosPromise,
  BudgetCreate,
  BudgetMotionRequest,
  BudgetUnitsAvaliable,
  EmailBudget,
  GetBudgetPromise,
  GetBudgetProps,
  GetBudgetReturn,
  RemoveProducFromCart,
} from "./interface";

export const GetClinicsBudgets = async (page = 1) => {
  try {
    const response = await API.get(`/budgets?type=5&page=${page}`);

    const formattedData = FormatterData(
      response.data.data ? response.data.data : []
    );

    return {
      status: response.status,
      data: formattedData,
      meta: response.data.meta ? response.data.meta : {},
    };
  } catch (error: any) {
    return {
      status: error.response.status,
      data: [],
      meta: {
        pagination: {},
      },
    };
  }
};

// verificar esta rota
export const GetOrdersBudgets = async (id: number) => {
  try {
    const response = await API.get(`budgets/orders/pacient/${id}`);

    const formattedData = FormatterData(
      response.data.data ? response.data.data : []
    );

    return {
      status: response.status,
      data: formattedData,
    };
  } catch (error: any) {
    return {
      status: error.response.status,
      data: [],
    };
  }
};

// verificar esta rota
export const GetDocumentsBudget = async (id: number) => {
  try {
    const response = await API.get(`/budgets/documents/${id}`);

    let dataResponse = [];

    if (!response.data.hasOwnProperty("error")) {
      dataResponse = response.data;
    }

    return {
      success: true,
      status: response.status,
      data: dataResponse,
    };
  } catch (error: any) {
    return {
      success: false,
      status: error.response.status,
      data: [],
    };
  }
};

// verificar esta rota
export const DeleteDocumentsBudget = async (
  budgetId: number,
  filePath: string
) => {
  const response = await API.delete(`/budgets/${budgetId}/documents`, {
    data: { file_path: filePath },
  });

  return {
    success: true,
    status: response.status,
    info: response.data.documents_base64,
  };
};

export const getBudgets = async ({ search, type }: GetBudgetProps) => {
  const { data } = await API.get<GetBudgetPromise>(
    `/budgets${type === "covenant" ? "?is_covenant=true" : ""}${
      type === "privado" ? "?is_covenant=false" : ""
    }`,
    {
      params: {
        ...search,
      },
    }
  );

  const { data: budgetList, meta } = data;

  const formattedData: ListBudget[] = FormatterData(budgetList ?? []);

  const pagination = {
    current_page: meta.pagination.current_page,
    per_page: meta.pagination.per_page,
    total_pages: meta.pagination.total_pages,
  };

  return {
    data: formattedData,
    pagination,
  };
};

export const GetBudgetsByUserID = async (
  user_id: string,
  page = 1,
  type: any
) => {
  const personType = type === "person" ? "person_id" : "lead_id";

  const response = await API.get(
    `/budgets?${personType}=${user_id}&page=${page}`
  );

  return response.data;
};

export const GetBudgetByID = async (
  budget_id: number | string
): Promise<GetBudgetReturn> => {
  const response = await API.get(`/budgets/${budget_id}`);

  return {
    success: true,
    info: FormatterBudget(response.data),
  };
};

export const DeleteBudgetByID = async (id: number) => {
  const response = await API.delete(`/budgets/${id}`);
  return response.data;
};

export const AddOrUpdateProductToCart = async ({
  budget_info,
  budget_type,
}: BudgetCreate) => {
  const response = await API.post(`/budgets/cart/${budget_type}`, budget_info);
  return {
    success: true,
    info: FormatterBudget(response.data),
  };
};

export const RemoveProductToCart = async ({
  productExclude,
  budget_type,
}: RemoveProducFromCart) => {
  const response = await API.post(
    `/budgets/cart/${budget_type}`,
    productExclude
  );

  if (response.status === 200) {
    return {
      success: true,
      info: FormatterBudget(response.data),
    };
  }
  return {
    success: false,
  };
};

export const ValidateCoupon = async (data: any) => {
  try {
    const response = await API.post(`/budgets/summary`, data);

    if (response.status === 200) {
      return {
        success: true,
        info: FormatterBudget(response.data.data),
      };
    }
    return {
      success: false,
    };
  } catch (error: any) {
    return {
      success: false,
      info: error.response.data,
    };
  }
};

export const SendBudgetEmail = async ({ budget_id, email }: EmailBudget) => {
  const postData = {
    email,
  };

  const response = await API.post(`/budgets/${budget_id}/mailer`, postData);

  return response.data;
};

export const cancelActiveBudget = async (budget_id: number) => {
  try {
    await API.post(`/budgets/${budget_id}/cancel`, { budget_id });
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

export const duplicateBudget = async (budget_id: number) => {
  try {
    const response = await API.post<BudgetProps>(
      `/budgets/${budget_id}/duplicate`
    );

    return response.data;
  } catch (error) {
    console.log({ error });
    throw error;
  }
};

export const addGeneralInfoAndDocuments = async (
  budgetId: number,
  formData: FormData
) => {
  const response = await API.post(`/budgets/${budgetId}`, formData);

  return response;
};

export const budgetCombos = async () => {
  try {
    const response = await API.get<BudgetCombosPromise>(`/budgets/combos`);

    return response.data;
  } catch (error: any) {
    throw new Error("error");
  }
};

export const validateZipCode = async (zipCode: string) => {
  try {
    const origin = "https://colabi.labiexames.com.br";

    const validCep = await Axios.get(
      `https://api.labiexames.com.br/site/home-collection/availability/zipcode?zip_code=${zipCode}&origin=${origin}`
    );

    if (validCep.status === 200) {
      createToast("success", validCep.data.message);
      return true;
    }
    return false;
  } catch (error: any) {
    createToast("error", error?.response.data.message);
    return false;
  }
};

export const unitsBudgetAvaliable = async (budget_id: number, page: number) => {
  const units = await API.get<BudgetUnitsAvaliable>(
    `budgets/${budget_id}/available_units?page=${page}`
  );

  return units.data;
};

export const linkUnitToBudget = async (
  budget_id: number,
  unit_id: number | null
) => {
  const units = await API.put<BudgetProps>(`budgets/${budget_id}/unit`, {
    unit_id,
  });

  return units.data;
};

export const budgetToMotion = async (motionData: BudgetMotionRequest) => {
  const response = await API.post(`budgets/integration/motion`, motionData);

  return response;
};
