import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FormattedAttendanceType, LastAddressType, useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { useShallow } from "zustand/react/shallow";
import { PageBody, PageContainer, PageContent } from "Pages/ServiceForm/styles";
import { OrderHeading } from "Components/ServiceForm/OrderHeading";
import { CartOrderDetails } from "Pages/ServiceForm/components/Cart/CartOrderDetails/CartOrderDeatails";

import link from 'Assets/Images/link.svg';
import pix from 'Assets/Images/pix.svg';
import creditCard from 'Assets/Images/credit-card.svg'
import money from "Assets/Images/money.svg"

import { Form, Formik, FormikErrors } from "formik";
import { getCardOperators } from "Requests/CardOperators";
// eslint-disable-next-line import/named
import { getCreditCardInstallments } from "Requests/CardInstallments";
import { toast } from "react-toastify";
import { MaskCPF, maskPhoneNumber } from "Utils/Masks";
import { ReactComponent as PlusIcon } from "Assets/Images/plus-icon.svg";
import * as Yup from "yup";
import { Button } from "Components/Button";
import { scroller } from "react-scroll";
import moment from "moment";

import { createBudgetOrder } from "Requests/Order/index";
import { cancelAppointment } from "Requests/CancelAppointment";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { CollectCards, Container, TitleCheckoutCollect } from "../style"
import { ArraysType, BooleansType, PaymentOptionType, PaymentType, StringsType, ObjectsType, NumbersType, MoneyType, CardType, PixType, LinkType, PaymentRequestType, PaymentStringsType } from "./types";
import { PaymentTypesSelect } from "../Collect/components/PaymentTypes";
import { Card } from "../Patient/components/PatientCard/styles";
import { PaymentCard } from "../Collect/components/PaymentCard";
import { calculateAmount, sumAmount } from "../functions";
import { ScheduleTimer } from "../ScheduleTimer";
import { PaymentConfirmationModal } from "../PaymentConfirmation";
import { OrderConfirmationModal } from "../OrderConfirmation";
import { CancelAppointmentModal } from "../CancelAppointment";

export const PaymentTypes = () => {

  const { order, setOrder, currentPaymentType, setCurrentPaymentType } = useBudgetOrderLocalStorage();

  const paymentOptions: PaymentType[] = [
    {
      image: money,
      label: 'Dinheiro',
      id: 'money',
      disabled: !!(order.coupon_percentage_discount === 100 && !order.homeCollectValue),
    },
    {
      image: creditCard,
      label: 'Cartão',
      id: 'card',
      disabled: !!(order.coupon_percentage_discount === 100 && !order.homeCollectValue),
    },
    {
      image: link,
      label: 'Checkout Remoto',
      id: 'link',
      disabled: !!(order.coupon_percentage_discount === 100 && !order.homeCollectValue),
    },
    {
      image: pix,
      label: 'Pix',
      id: 'pix',
      disabled: !!(order.coupon_percentage_discount === 100 && !order.homeCollectValue),
    },
  ];


  const history = useHistory();

  const emptyPatientForm: PaymentOptionType = {
    money: {
      received: 0,
      remain: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      change: 0,
      completed: false
    },
    card: {
      addition: 0,
      flag: 1,
      installments: 0,
      nsu: '',
      total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      type: 0,
      selectedInstallment: 0,
      received: 0,
      completed: false
    },
    pix: {
      nsu: '',
      total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      received: 0,
      completed: false
    },
    link: {
      name: order.payer_patient.name,
      cpf: order.payer_patient.document.type === 'cpf' ? MaskCPF(order.payer_patient.document.number) : order.payer_patient.document.number,
      email: order.payer_patient.email,
      phone: `(${order.payer_patient.phone.ddd}) ${maskPhoneNumber(order.payer_patient.phone.phone)}`,
      received: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
    }
  }

  const [setCurrentScreen, isViewBudget] = useBudgetOrder(useShallow((state) => [
    state.setCurrentScreen,
    state.isViewBudget,
  ]));

  const [strings, setStrings] = useState<StringsType>({
    selectedId: '',
  });

  const [paymentStrings, setPaymentStrings] = useState<PaymentStringsType>({
    payment_link: '',
    order_total_price: '',
    order_id: ''
  })

  const [formattedAttendances, setFormattedAttendances] = useState<FormattedAttendanceType[]>([]);
  const [lastAddress, setLastAddress] = useState<LastAddressType | null>(null);

  const [objects, setObjects] = useState<ObjectsType>({
    paymentForm: {
      ...emptyPatientForm
    }
  });

  const [arrays, setArrays] = useState<ArraysType>({
    card_banners: [],
    credit_card_installments: [],
  });

  const [booleans, setBooleans] = useState<BooleansType>({
    loadingCardBanners: false,
    loadingInstallments: false,
    orderConfirmationModal: false,
    paymentConfirmationModal: false,
    cancelAppointmentModal: false,
  });

  const [paymentTypes, setPaymentTypes] = useState<PaymentType[]>([...paymentOptions]);

  const [numbers, setNumbers] = useState<NumbersType>({
    currentIndexPayment: 0,
  })

  useEffect(() => {
    if (order.budget_id === 0) history.push('/orcamentos/particular#/')
    setCurrentScreen('payment_type');
    fetchCardBanners();
    checkCurrentSelected();
    checkStorageInfoWithoutFormik()
  }, []);

  const checkCurrentSelected = () => {
    if (currentPaymentType.length > 0) {
      const selectedIdValue = currentPaymentType[currentPaymentType.length - 1];

      setStrings((prevState) => ({
        ...prevState,
        selectedId: selectedIdValue,
      }));
    }
  };

  const initializePaymentForm = () => ({
    money: {
      received: 0,
      remain: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      change: 0,
    },
    card: {
      addition: 0,
      flag: 1,
      installments: 0,
      nsu: '',
      total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      type: 0,
      selectedInstallment: 0,
      received: 0,
    },
    pix: {
      nsu: '',
      total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      received: 0,
    },
    link: {
      name: order.payer_patient.name,
      cpf:
        order.payer_patient.document.type === 'cpf'
          ? MaskCPF(order.payer_patient.document.number)
          : order.payer_patient.document.number,
      email: order.payer_patient.email,
      phone: `(${order.payer_patient.phone.ddd}) ${maskPhoneNumber(order.payer_patient.phone.phone)}`,
      value: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
      received: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
    },
  });

  const handleUpdatedState = (
    paymentForm: PaymentOptionType,
    currentPaymentType: string[],
  ): ObjectsType => {
    const { payment_info } = order;

    if (payment_info[payment_info.length - 1]) {
      return {
        paymentForm:
          currentPaymentType.length - 1 === payment_info.length - 1
            ? { ...paymentForm, ...payment_info[payment_info.length - 1] }
            : { ...paymentForm },
      };
    }
    return { paymentForm };
  };

  const commonCheckStorageInfo = (
    setNumbers: React.Dispatch<React.SetStateAction<NumbersType>>,
    mountPaymentOptions: () => void,
    currentPaymentType: string[]
  ) => {
    setNumbers((prevState) => ({
      ...prevState,
      currentIndexPayment: currentPaymentType.length - 1,
    }));

    mountPaymentOptions();
  };

  const checkStorageInfoWithoutFormik = () => {
    const { payment_info } = order;

    if (payment_info.length === 0) return;

    const initialPaymentForm = initializePaymentForm();

    const updatedState = handleUpdatedState(
      initialPaymentForm,
      currentPaymentType,
    );

    setObjects((prevState) => ({
      ...prevState,
      ...updatedState,
    }));

    commonCheckStorageInfo(setNumbers, mountPaymentOptions, currentPaymentType);
  };

  const checkStorageInfoWithFormik = (
    setValues: (values: React.SetStateAction<PaymentOptionType>, shouldValidate?: boolean) => Promise<void | FormikErrors<PaymentOptionType>>
  ) => {
    const { payment_info } = order;

    if (payment_info.length === 0) return;

    const initialPaymentForm = initializePaymentForm();

    const updatedState = handleUpdatedState(
      initialPaymentForm,
      currentPaymentType,
    );

    setValues({ ...updatedState.paymentForm });

    commonCheckStorageInfo(setNumbers, mountPaymentOptions, currentPaymentType);
  };

  const handleSelectPaymentType = (id: string) => {
    setStrings((prevState) => ({
      ...prevState,
      selectedId: id
    }));
    const clonendCurrentPayment: string[] = [...currentPaymentType];
    clonendCurrentPayment[numbers.currentIndexPayment] = id;
    setCurrentPaymentType(clonendCurrentPayment);


    if (id === 'link') {
      const clonnedPaymentInfo = [...order.payment_info];
      clonnedPaymentInfo.push({
        money: {
          received: 0,
          remain: 0,
          total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
          change: 0,
          completed: false
        },
        card: {
          addition: 0,
          flag: 1,
          installments: 0,
          nsu: '',
          total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
          type: 0,
          selectedInstallment: 0,
          received: 0,
          completed: false
        },
        pix: {
          nsu: '',
          total: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
          received: 0,
          completed: false
        },
        link: {
          name: order.payer_patient.name,
          cpf: order.payer_patient.document.type === 'cpf' ? MaskCPF(order.payer_patient.document.number) : order.payer_patient.document.number,
          email: order.payer_patient.email,
          phone: `(${order.payer_patient.phone.ddd}) ${maskPhoneNumber(order.payer_patient.phone.phone)}`,
          // value: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
          received: (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0),
        }
      });

      setOrder({
        ...order,
        payment_info: clonnedPaymentInfo
      })
    }
  }

  const handleSubmit = async (values: PaymentOptionType, setSubmitting: (value: boolean) => void, setFieldError: (field: string, message: string | undefined) => void) => {

    try {

      if (!strings.selectedId && order.coupon_percentage_discount != 100) {
        setSubmitting(false);
        return toast.error('Para adicionar mais um meio de pagamento, preencha o atual.')
      }

      const hasFieldErrors = await validateFields(values, setFieldError, true);

      if (hasFieldErrors) {
        return setSubmitting(false);
      }

      const payment_info = mountPaymentData();

      const orderData = {
        ...order.orderData,
        order: {
          ...order.orderData.order,
          payments: payment_info
        }
      }

      const createdOrder: any = await createBudgetOrder(orderData)

      setPaymentStrings({
        ...paymentStrings,
        order_id: String(createdOrder.ORDER.original.data.id),
        order_total_price: createdOrder.ORDER.original.data.total,
        payment_link: createdOrder.ORDER.original.data.payment_link
      });
      setFormattedAttendances(order.formattedAttendances);
      setLastAddress(order.lastAddress);

      if (order.coupon_percentage_discount === 100) {
        // modal de confirmaca de pagamento
        setBooleans({
          ...booleans,
          orderConfirmationModal: true
        })
      } else {
        setBooleans({
          ...booleans,
          paymentConfirmationModal: true
        })
      }
      setSubmitting(false);
      setCurrentPaymentType([]);
      setOrder({
        budget_id: 0,
        appointment_type_id: "",
        payment_info: [],
        isTaxInserted: false,
        payer_patient: {
          id: 0,
          birthdate: '',
          document: {
            id: 0,
            number: '',
            type: '',
            country_id: 0
          },
          email: '',
          gender_id: 0,
          mother_name: '',
          name: '',
          phone: {
            id: 0,
            ddd: 0,
            ddi: 0,
            phone: 0
          },
          unborn: false,
          social_name: ''
        },
        lead: {
          id: 0,
          ddd: null,
          phone: '',
          email: null,
          name: '',
        },
        attendances: [],
        itemsQuantity: 0,
        totalPrice: '0,00',
        budget_type: 1,
        coupon_percentage_discount: 0,
        homeCollectValue: 0,
        isCovenant: false,
        discounts: 0,
        subtotal: 0,
        deliveryTime: '',
        items: [],
        product_categories: [],
        formattedAttendances: [],
        session_start: "",
        session_end: "",
        lastAddress: {
          street: "",
          number: "",
          uf: "",
          neighborhood: "",
          city: "",
          channel_id: 4,
          complement: '',
          country_id: 26,
          default: true,
          reference_point: '',
          type_id: 1,
          zip_code: ''
        },
        attendanceLabel: [],
        dependents: [],
        patients: [],
        patientWithProducts: [],
        cupon_code: '',
        home_collected: false,
        home_collect_value: '',
        orderData: {
          channel_id: 4,
          order_type: 1,
          appointment_type_id: "",
          commercial_category: 63,
          commercial_document_number: '',
          payer_id: 0,
          order: {
            budget_id: 0,
            subtotal: '',
            discounts: '',
            total: 0,
            coupon_code: null,
            home_collected: false,
            home_collect_value: '',
            home_collect_date: '',
            vaccine_cards: [],
            cart: [],
            home_collect_schedule: {
              id_home_collected: 0,
              home_collect_date: "",
              zip_code: "",
              country: "Brasil",
              uf: "",
              city: "",
              neighborhood: "",
              street: "",
              number: "",
              complement: "",
              reference_point: "",
              address_type_id: 1,
            }
          }
        }
      })
    } catch (err) {
      console.error(err);
      toast.error('Erro ao criar o pedido')
      setSubmitting(false);
    }
  }

  const mountPaymentData = () => {
    let data: PaymentRequestType[] = [];

    if (order.coupon_percentage_discount === 100 && !order.homeCollectValue) {
      data.push({
        payment_type: 1,
        installments_number: 1,
        operation_type: 'DINHEIRO',
        transaction_data: moment().format('YYYY-MM-DDTHH:mm:ss'),
        card_banner_id: null,
        credit_card_banner: null,
        nsu_number: null,
        addition: 0,
        value: 0
      })
    } else {
      data = order.payment_info.map((item, index) => {
        return {
          payment_type: mountPaymentType(currentPaymentType[index], item),
          installments_number: currentPaymentType[index] === 'card' ? item.card.type === 1 ? 1 : item.card.selectedInstallment : 1,
          operation_type: mountOperationType(currentPaymentType[index], item),
          transaction_data: moment().format('YYYY-MM-DDTHH:mm:ss'),
          card_banner_id: currentPaymentType[index] === 'card' ? item.card.flag : null,
          credit_card_banner: currentPaymentType[index] === 'card' ? arrays.card_banners.find((a) => a.id === item.card.flag)?.card_banner ?? "" : null,
          nsu_number: currentPaymentType[index] === 'card' ? String(item.card.nsu) : currentPaymentType[index] === 'pix' ? String(item.pix.nsu) : null,
          addition: currentPaymentType[index] === 'card' ? item.card.addition : 0,
          value: mountValue(currentPaymentType[index], item)
        };
      });
    }

    return data;
  };

  const mountPaymentType = (key: string, item: PaymentOptionType): number => {
    switch (key) {
      case "money":
        return 1; // Dinheiro
      case "card":
        return item.card.type === 1 ? 2 : 3; // Débito ou Crédito
      case "link":
        return 4; // Link
      case "pix":
        return 5; // Pix
      default:
        throw new Error(`Tipo de pagamento desconhecido: ${key}`);
    }
  };

  const mountValue = (key: string, item: PaymentOptionType): number => {
    switch (key) {
      case "money":
        return item.money.received; // Dinheiro
      case "card":
        return item.card.received + item.card.addition; // Débito ou Crédito
      case "link":
        return item.link.received; // Link
      case "pix":
        return item.pix.received; // Pix
      default:
        throw new Error(`Tipo de pagamento desconhecido: ${key}`);
    }
  };

  const mountOperationType = (key: string, item: PaymentOptionType): string => {
    switch (key) {
      case "money":
        return "DINHEIRO"; // Dinheiro
      case "card":
        return item.card.type === 1 ? 'DEBITO' : 'CREDITO'; // Débito ou Crédito
      case "link":
        return 'LINK'; // Link
      case "pix":
        return 'PIX'; // Pix
      default:
        return ''
    }
  }

  const validateFields = (values: PaymentOptionType, setFieldError: (field: string, message: string | undefined) => void, validateQuantity: boolean) => {
    let hasError = false;
    const total = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);

    if (strings.selectedId === 'money') {

      const amountVerification = calculateAmount(currentPaymentType, order.payment_info, total);

      if (!values.money.received) {
        setFieldError('money.received', 'Campo Obrigatório');
        hasError = true;
      }

      if (
        values.money.received
        && amountVerification.isLowerTotal
        && validateQuantity) {
        setFieldError('money.received', 'Montante de Valores é inferior ao total.');
        scrollToElement('money.received');
        hasError = true;
      }

      if (
        values.money.received
        && !amountVerification.isLowerTotal
        && !amountVerification.sameValue
      ) {
        setFieldError('money.received', 'Montante de Valores é superior ao total.');
        scrollToElement('money.received');
        hasError = true;
      }


    }

    if (strings.selectedId === 'card') {
      const amountVerification = calculateAmount(currentPaymentType, order.payment_info, total);

      if (!values.card.type) {
        setFieldError('card.type', 'Campo Obrigatório');
        scrollToElement('card.type');
        hasError = true;
      }
      if (!values.card.received) {
        setFieldError('card.received', 'Campo Obrigatório');
        scrollToElement('card.received');
        hasError = true;
      }

      if (
        values.card.received
        && amountVerification.isLowerTotal
        && validateQuantity) {
        setFieldError('card.received', 'Montante de Valores é inferior ao total.');
        scrollToElement('card.received');
        hasError = true;
      }

      if (
        values.card.received
        && !amountVerification.isLowerTotal
        && !amountVerification.sameValue
      ) {
        setFieldError('card.received', 'Montante de Valores é superior ao total.');
        scrollToElement('card.received');
        hasError = true;
      }



      if (!values.card.nsu) {
        setFieldError('card.nsu', 'Campo Obrigatório');
        scrollToElement('card.nsu');
        hasError = true;
      }
      if (values.card.type === 2 && !values.card.selectedInstallment) {
        toast.error('Selecione a quantidade de parcelas');
        hasError = true;
      }
    }

    if (strings.selectedId === 'pix') {


      const amountVerification = calculateAmount(currentPaymentType, order.payment_info, total);

      if (!values.pix.nsu) {
        setFieldError('pix.nsu', 'Campo Obrigatório');
        scrollToElement('pix.nsu');
        hasError = true;
      }
      if (!values.pix.received) {
        setFieldError('pix.received', 'Campo Obrigatório');
        scrollToElement('pix.received');
        hasError = true;
      }

      if (
        values.pix.received
        && amountVerification.isLowerTotal
        && validateQuantity) {
        setFieldError('pix.received', 'Montante de Valores é inferior ao total.');
        scrollToElement('pix.received');
        hasError = true;
      }

      if (
        values.pix.received
        && !amountVerification.isLowerTotal
        && !amountVerification.sameValue
      ) {
        setFieldError('pix.received', 'Montante de Valores é superior ao total.');
        scrollToElement('pix.received');
        hasError = true;
      }
    }

    return hasError
  }

  const scrollToElement = (id: string) => {
    scroller.scrollTo(id, {
      duration: 500,
      delay: 0,
      smooth: 'easeInOutQuart',
      offset: -100,
    });
  }

  const addAnotherPayment = async (
    values: PaymentOptionType,
    setFieldError: (field: string, message: string | undefined) => void,
    setValues: (values: React.SetStateAction<PaymentOptionType>, shouldValidate?: boolean) => Promise<void | FormikErrors<PaymentOptionType>>,
    errors: FormikErrors<PaymentOptionType>) => {

    const total = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);
    if (calculateAmount(currentPaymentType, order.payment_info, total).sameValue) return toast.error('Não é permitido adicionar um segundo meio de pagamento, quando montante de valor inserido é igual ao total do pedido.')
    if (Object.keys(errors).length > 0) return toast.error('Verifique se todos os campos estão preenchidos para prosseguir.');

    if (!strings.selectedId) return toast.error('Para adicionar mais um meio de pagamento, preencha o atual.')
    if (strings.selectedId === 'link') return toast.error('Não é possível adicionar um segundo pagamento quando o primeiro é um link de pagamento.')

    const hasError = await validateFields(values, setFieldError, false);

    if (hasError) {
      return toast.error('Verifique se todos os campos estão preenchidos para prosseguir.');
    }

    await mountPaymentOptions()

    await markPaymentAsCompleted()


    if (order.payment_info[numbers.currentIndexPayment + 1]) {
      checkCurrentSelected();
      checkStorageInfoWithFormik(setValues)
    } else {
      setStrings((prevState) => ({
        ...prevState,
        selectedId: ''
      }));
      const totalValue = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0)

      setValues({
        money: {
          received: 0,
          remain: totalValue - sumAmount(currentPaymentType, order.payment_info),
          total: totalValue,
          change: 0,
          completed: false
        },
        card: {
          addition: 0,
          flag: 1,
          installments: 0,
          nsu: '',
          total: totalValue - sumAmount(currentPaymentType, order.payment_info),
          type: 0,
          selectedInstallment: 0,
          received: 0,
          completed: false
        },
        pix: {
          nsu: '',
          total: totalValue,
          received: 0,
          completed: false
        },
        link: {
          name: order.payer_patient.name,
          cpf: order.payer_patient.document.type === 'cpf' ? MaskCPF(order.payer_patient.document.number) : order.payer_patient.document.number,
          email: order.payer_patient.email,
          phone: `(${order.payer_patient.phone.ddd}) ${maskPhoneNumber(order.payer_patient.phone.phone)}`,
          received: totalValue,
        }
      });

      setNumbers((prevState) => ({
        ...prevState,
        currentIndexPayment: prevState.currentIndexPayment + 1
      }))
    }


  }


  const markPaymentAsCompleted = () => {
    // Clonar o array para evitar mutações diretas
    const updatedPaymentInfo = [...order.payment_info];

    const paymentType = currentPaymentType[numbers.currentIndexPayment];

    if (paymentType && updatedPaymentInfo[numbers.currentIndexPayment]?.[paymentType as keyof PaymentOptionType]) {
      // Tipar explicitamente para adicionar `completed`
      const paymentData = updatedPaymentInfo[numbers.currentIndexPayment][paymentType as keyof PaymentOptionType] as Partial<
        MoneyType & CardType & PixType & LinkType
      >;

      // Adicionar `completed` como true
      paymentData.completed = true;

      updatedPaymentInfo[numbers.currentIndexPayment][paymentType as keyof PaymentOptionType] = paymentData as any;
    }

    setOrder({
      ...order,
      payment_info: updatedPaymentInfo
    });
  };



  const mountPaymentOptions = () => {
    const clonnedPaymentOptions: PaymentType[] = paymentOptions.map((i) => {
      // Desabilita os itens com base nas condições para o array currentPaymentType
      const isPixOrCard = currentPaymentType.includes('pix') || currentPaymentType.includes('card');
      const isMoney = currentPaymentType.includes('money');

      return {
        ...i,
        disabled: !!(
          (isPixOrCard && i.id === 'link') ||
          (isMoney && (i.id === 'money' || i.id === 'link'))
        ),
      };
    });

    setPaymentTypes(clonnedPaymentOptions);
  };

  const fetchCardBanners = async () => {
    try {
      setBooleans((prevState) => ({
        ...prevState,
        loadingCardBanners: true
      }));
      const cardOperators = await getCardOperators();
      setArrays({
        ...arrays,
        card_banners: cardOperators.card_banners
      });
      setBooleans((prevState) => ({
        ...prevState,
        loadingCardBanners: false
      }))
    } catch (err) {
      console.error(err);
      toast.error('Erro ao buscar bandeiras de cartão')
      setBooleans((prevState) => ({
        ...prevState,
        loadingCardBanners: false
      }));
    }
  }

  const fetchInstallments = async (value: number) => {
    try {
      setBooleans((prevState) => ({
        ...prevState,
        loadingInstallments: true
      }))

      const response = await getCreditCardInstallments(value);

      setArrays((prevState) => ({
        ...prevState,
        credit_card_installments: response.body
      }))
      setBooleans((prevState) => ({
        ...prevState,
        loadingInstallments: false
      }));
    } catch (err) {
      console.error(err);
      toast.error('Erro ao buscar parcelamentos de cartão');
      setBooleans((prevState) => ({
        ...prevState,
        loadingCardBanners: false
      }));
    }
  }

  const paymentFormSchema = Yup.object().shape({
    money: Yup.object().shape({
      received: Yup.number().notRequired()
    }),
    card: Yup.object().shape({
      type: Yup.number().notRequired(),
      nsu: Yup.string(),
    }),
    pix: Yup.object().shape({
      nsu: Yup.string(),
    })
  });

  const handleEditPayment = (setValues: (values: React.SetStateAction<PaymentOptionType>, shouldValidate?: boolean) => Promise<void | FormikErrors<PaymentOptionType>>, index: number) => {

    const paymentToEdit = order.payment_info[index];
    if (!paymentToEdit) return; // Evite erros ao acessar um índice inexistente

    setValues({
      ...emptyPatientForm,
      ...paymentToEdit, // Garanta que só os campos esperados sejam atualizados
    });

    setNumbers((prevState) => ({
      ...prevState,
      currentIndexPayment: index
    }))

    setStrings((prevState) => ({
      ...prevState,
      selectedId: currentPaymentType[index],
    }));
  }

  const handleCancelSchedule = async () => {

    try {
      const idSchedule = order.formattedAttendances.find((i) => i.id_scheduling != 0 && i.type === "home_collect")?.id_scheduling;
      if (idSchedule) {
        await cancelAppointment(idSchedule);

        setCurrentPaymentType([])
        setOrder({
          budget_id: 0,
          appointment_type_id: "",
          payment_info: [],
          isTaxInserted: false,
          payer_patient: {
            id: 0,
            birthdate: '',
            document: {
              id: 0,
              number: '',
              type: '',
              country_id: 0
            },
            email: '',
            gender_id: 0,
            mother_name: '',
            name: '',
            phone: {
              id: 0,
              ddd: 0,
              ddi: 0,
              phone: 0
            },
            unborn: false,
            social_name: ''
          },
          lead: {
            id: 0,
            ddd: null,
            phone: '',
            email: null,
            name: '',
          },
          attendances: [],
          itemsQuantity: 0,
          totalPrice: '0,00',
          budget_type: 1,
          coupon_percentage_discount: 0,
          homeCollectValue: 0,
          isCovenant: false,
          discounts: 0,
          subtotal: 0,
          deliveryTime: '',
          items: [],
          product_categories: [],
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 26,
            default: true,
            reference_point: '',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: '',
          home_collected: false,
          home_collect_value: '',
          orderData: {
            channel_id: 4,
            order_type: 1,
            appointment_type_id: "",
            commercial_category: 63,
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              coupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        })
      }

    } catch (err) {
      console.error(err);
      toast.error("Erro ao cancelar o agendamento.");
    }
  };

  const handleOpenCancelModal = () => {
    setBooleans({ ...booleans, cancelAppointmentModal: true })
  }

  const handleCloseCancelModal = () => {
    setBooleans({
      ...booleans,
      cancelAppointmentModal: false
    });
    const { budget_id } = order

    handleCancelSchedule();

    if (isViewBudget) {
      history.push(`/orcamento/${budget_id}`);
    } else {
      history.push(`/ficha-de-atendimento/person/selecionar-produtos/${budget_id}?btype=private`);
    }
  }

  const isPaymentCompleted = (
    payment_info: PaymentOptionType[],
    currentPaymentType: string[],
    index: number
  ): boolean => {
    const paymentType = currentPaymentType[index];
    const paymentData = payment_info[index]?.[paymentType as keyof PaymentOptionType];
    return paymentData?.completed === true;
  };



  return (
    <PageContainer className="entry-app-content entry-budget">
      {order.budget_id != 0 ? <>
        <PageContent className="content-holder page-budget">
          <OrderHeading
            list={[
              {
                path: "/orcamentos",
                label: "Orçamento",
                current: false,
              },
              {
                path: "#",
                label: "Converter",
                current: false,
              },
              {
                path: "#",
                label: "Pagamento",
                current: true,
              },
            ]}
            title="Converter em Pedido"
            payerPatient={order.payer_patient}
          />
          <PageBody className="cart-order-budget">
            <Container className="card-search-information gray-background">
              <TitleCheckoutCollect>Selecione a forma de Pagamento</TitleCheckoutCollect>
              {order.session_end ?
                <ScheduleTimer
                  expired={() => handleOpenCancelModal()}
                  content="Para garantir que sua reserva seja confirmada, por favor finalize a identificação e o pagamento do seu pedido dentro do prazo ao lado. Depois desse período sua reserva será liberada para venda novamente."
                />
                : <></>}

              <Formik
                initialValues={objects.paymentForm}
                // validateOnChange={false}
                enableReinitialize
                validationSchema={paymentFormSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                  handleSubmit(values, setSubmitting, setFieldError);
                }}
              >
                {({ values, isSubmitting, setFieldError, setValues, errors }) => (
                  <Form>


                    {order.payment_info.map((i, index) => {

                      const isCompleted = isPaymentCompleted(order.payment_info, currentPaymentType, index);


                      if (isCompleted) {
                        return (
                          <PaymentCard
                            selectedOption={currentPaymentType[index]}
                            payment={i}
                            paymentOptions={paymentOptions}
                            cardBanners={arrays.card_banners}
                            handleEditPayment={() => handleEditPayment(setValues, index)}
                            index={index}
                          />
                        )
                      }
                      return <Fragment key={index} />;
                    })}

                    <CollectCards>
                      {paymentTypes.map((i, index) => {
                        return (
                          <Fragment key={index}>
                            <PaymentTypesSelect
                              paymentType={i}
                              selected={strings.selectedId}
                              handleSelect={(id: string) => handleSelectPaymentType(id)}
                              values={values}
                              cardFlags={arrays.card_banners}
                              loadingCardBanners={booleans.loadingCardBanners}
                              loadingInstallments={booleans.loadingInstallments}
                              fetchInstallments={(value: number) => fetchInstallments(value)}
                              cardInstallments={arrays.credit_card_installments}
                              currentIndexPayment={numbers.currentIndexPayment}
                            />
                          </Fragment>
                        )
                      })}
                    </CollectCards>

                    <div style={{ width: "100%", marginTop: 10, cursor: 'pointer' }} onClick={() => addAnotherPayment(values, setFieldError, setValues, errors)}>
                      <Card>
                        <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
                          <h3 className="addPatientTitle">ADICIONAR MAIS UMA FORMA DE PAGAMENTO</h3>
                          <div style={{ margin: 0, cursor: "pointer", marginTop: '8px', marginRight: '10px' }}>
                            <PlusIcon />
                          </div>
                        </div>
                      </Card>
                    </div>

                    <div style={{
                      bottom: 0,
                      width: '100%',
                      backdropFilter: 'saturate(180%) blur(5px)',
                      WebkitBackdropFilter: 'saturate(180%) blur(20px)',
                      display: 'flex',
                      justifyContent: 'space-between',
                      padding: '1em 0',
                      borderRadius: '1em'
                    }}>
                      <div style={{ justifyContent: 'left', marginLeft: '-3em', width: '10em' }}>

                        <Button textButton width="full" minHeight="3.7em" fontWeight="600" onClick={() => history.push('/pedidos/criar-pedido/identificacao')}>
                          voltar
                        </Button>

                      </div>

                      <div style={{ width: '10em' }}>
                        <Button width="full" minHeight="3.7em" fontWeight="600" type="submit" loading={isSubmitting}>
                          Avançar
                        </Button>
                      </div>
                    </div>
                  </Form>
                )}
              </Formik>
            </Container>
            <CartOrderDetails />
          </PageBody>
        </PageContent>
      </> : <></>
      }

      {booleans.paymentConfirmationModal ?
        <PaymentConfirmationModal
          isOpen={booleans.paymentConfirmationModal}
          closeModal={() => setBooleans((prevState) => ({ ...prevState, paymentConfirmationModal: true }))}
          paymentLink={paymentStrings.payment_link}
          orderId={paymentStrings.order_id}
          orderPrice={paymentStrings.order_total_price}
        />
        : <></>}


      {booleans.orderConfirmationModal ?
        <OrderConfirmationModal
          isOpen={booleans.orderConfirmationModal}
          closeModal={() => setBooleans((prevState) => ({ ...prevState, orderConfirmationModal: true }))}
          formattedAttendances={formattedAttendances}
          lastAddress={lastAddress}
          orderId={paymentStrings.order_id}
        />
        : <></>}

      {booleans.cancelAppointmentModal ?
        <CancelAppointmentModal
          isOpen={booleans.cancelAppointmentModal}
          handleClose={() => handleCloseCancelModal()}
        />
        : <></>}

    </PageContainer >
  )
}