
import { Container } from "./styles"
import { PaymentTypesProps } from "./types"
import { PaymentAccordion } from "../PaymentAccordion";
import { MoneyType } from "./components/money";
import { CardType } from "./components/card";
import { PixType } from "./components/pix";
import { LinkType } from "./components/link";


export const PaymentTypesSelect = ({
  paymentType,
  selected,
  handleSelect,
  values,
  cardFlags,
  loadingCardBanners,
  loadingInstallments,
  fetchInstallments,
  cardInstallments,
  currentIndexPayment
}: PaymentTypesProps) => {

  const switchPaymentType = () => {
    switch (paymentType.id) {
      case 'money':
        return (
          <MoneyType
            values={values}
            currentIndexPayment={currentIndexPayment}
          />
        )
      case 'card':
        return (
          <CardType
            values={values}
            cardFlags={cardFlags}
            loadingCardBanners={loadingCardBanners}
            loadingInstallments={loadingInstallments}
            fetchInstallments={(value: number) => fetchInstallments(value)}
            cardInstallments={cardInstallments}
            currentIndexPayment={currentIndexPayment}
          />
        )
      case 'pix':
        return (
          <PixType
            values={values}
            currentIndexPayment={currentIndexPayment}
          />
        )
      case 'link':
        return (
          <LinkType values={values} />
        )
      default:
        break;
    }
  }

  return (
    <Container
      className={`
        collect-cards
        ${selected === paymentType.id ? "_selected" : ""}
        ${paymentType.disabled ? "_inactive" : ""}
    `} 
      onClick={() => {
        if (paymentType.disabled) return
        handleSelect(paymentType.id)
      }}
    >
      <div className="budgeting-header">
        <div className="info-item">
          <figure>
            <picture>
              <img src={paymentType.image} alt={paymentType.label} loading="lazy" />
            </picture>
          </figure>
          <p className="text-lgray text-no-margin text-left text-info-title" style={{ fontWeight: 600 }}>
            {paymentType.label}
          </p>
        </div>
      </div>
      {selected === paymentType.id ? <PaymentAccordion cssClass="" isOpen={selected === paymentType.id}>
        <div style={{ width: '100%', paddingLeft: 10 }}>
          {switchPaymentType()}
        </div>
      </PaymentAccordion> : <></>}

    </Container>
  )

}