import { RecentOrdersProps } from "Pages/Person/interface";
import { formatDateBR } from "Utils/Date";

interface OrderHistoryProps {
  recentOrders: RecentOrdersProps[];
}

export const OrderHistory = ({ recentOrders }: OrderHistoryProps) => {
  return (
    <div className="dashboard-interaction-history">
      {recentOrders.length ? (
        <>
          <span className="dashboard-sub-title">HISTÓRICO DE INTERAÇÕES</span>
          <table className="">
            <thead>
              <tr>
                <th>Tipo</th>
                <th>Data</th>
                <th>ID</th>
                <th>Total</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              {recentOrders.length > 0 &&
                recentOrders.map((order) => (
                  <tr key={order.id}>
                    <td>{order.type === "Order" ? "Pedido" : "Orçamento"}</td>
                    <td>{formatDateBR(order.date)}</td>
                    <td>{order.id}</td>
                    <td>R$ {order.totalDisplay}</td>
                    <td>{order.status}</td>
                  </tr>
                ))}
            </tbody>
          </table>
        </>
      ) : (
        <span className="dashboard-sub-title">Nenhuma interação</span>
      )}
    </div>
  );
};
