import { replaceStringNumber } from "Utils/ReplaceStringNumber"
import { Button } from "Components/Button"
import { Card } from "../../../Patient/components/PatientCard/styles"
import { PaymentCardProps } from "./types"
import { CardContent } from "./styles"

export const PaymentCard = ({ selectedOption, payment, paymentOptions, cardBanners, handleEditPayment, index }: PaymentCardProps) => {

  const findImage = () => paymentOptions.find((a) => a.id === selectedOption)?.image

  const mountTitle = () => {
    let title = '';
    switch (selectedOption) {
      case 'money':
        title = 'Dinheiro'
        break;
      case 'card':
        title = 'Cartão'
        break;
      case 'pix':
        title = 'Pix'
        break;
      default:
        break;
    }
    return title
  }

  const mountContent = () => {

    if (selectedOption === 'money') {
      return (
        <>
          <p><span className="label">Recebido:</span> <strong>{replaceStringNumber(payment.money.received)}</strong></p>
        </>
      )
    }

    if (selectedOption === 'card') {
      return (
        <>
          <p><span className="label">{payment.card.type === 2 ? 'Crédito:' : 'Débito:'}</span><strong> {payment.card.type === 2  ? `${payment.card.selectedInstallment}x ${payment.card.addition ? 'com juros' : 'sem juros'}` : 'á vista'} </strong></p>
          <p><span className="label">Bandeira do Cartão:</span><strong> {cardBanners.find((a) => a.id === payment.card.flag)?.card_banner}</strong></p>
          <p><span className="label">Nsu da Transação:</span> <strong>{payment.card.nsu}</strong></p>
          <p><span className="label">Recebido:</span> <strong>{replaceStringNumber(payment.card.received)}</strong></p>

        </>
      )
    }

    if (selectedOption === 'pix') {
      return (
        <>
          <p><span className="label">Nsu da Transação:</span> <strong>{payment.pix.nsu}</strong></p>
          <p><span className="label">Recebido:</span> <strong>{replaceStringNumber(payment.pix.received)}</strong></p>
        </>
      )
    }
  }

  return (
    <>
      <Card>
        <CardContent>
          <div className="header">
            <span className="title">PAGAMENTO {index + 1}</span>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'row', gap: 10, marginTop: '1em' }}>
            <figure style={{ margin: 0 }}>
              <picture>
                <img src={findImage()} loading="lazy" />
              </picture>
            </figure>
            <div><span className="subtitle">{mountTitle()}</span></div>
          </div>
          <div style={{ display: 'flex', width: '100%', flexDirection: 'column', marginTop: '1em', marginLeft: 30 }}>
            {mountContent()}
          </div>
          <div className="button-container">
            <div>
              <Button outlined width="full" minHeight="3.7em" fontWeight="600" onClick={handleEditPayment}>
                editar
              </Button>
            </div>
          </div>
        </CardContent>
      </Card>
    </>
  )

}