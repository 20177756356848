import { TabProps } from "Pages/Person/interface";
import moment from "moment";
import { FormatDate } from "Utils/FormatterDate";
import { MaskCPF, MaskCelphone, MaskLandline } from "Utils/Masks";

export const ClientInfo = ({ person }: TabProps) => {
  return (
    <div className="dashboard-person-data">
      <span className="dashboard-sub-title">DADOS CADASTRAIS</span>

      <div className="general-data-div">
        <div>
          <div className="wrapper-info">
            <p>
              <strong>Nome: </strong>
              {person.full_name}
            </p>

            {person.social_name && (
              <p>
                <strong>Nome social: </strong>
                {person.social_name}
              </p>
            )}

            <p>
              <strong>Gênero: </strong>
              {person.gender ? person.gender.gender : "Gênero não informado"}
            </p>

            {person.birthdate && (
              <p>
                <strong>Data de nascimento:</strong>
                {moment(person.birthdate, "DD/MM/YYYY").isValid()
                  ? person.birthdate
                  : FormatDate(person?.birthdate)}
              </p>
            )}
            <p>
              <strong>Recém nascido:</strong>
              {person.unborn ? "Sim" : "Não"}
            </p>
          </div>
        </div>

        <div>
          {person?.phones &&
            person?.phones.length > 0 &&
            person?.phones.map((phone, i) => (
              <p key={i}>
                <strong>Telefone: </strong>({phone.ddd}){" "}
                {JSON.stringify(phone.phone).length === 8
                  ? MaskLandline(phone.phone)
                  : MaskCelphone(phone.phone)}
              </p>
            ))}

          {person?.emails &&
            person?.emails.length > 0 &&
            person?.emails.map((email) => (
              <p key={email.id}>
                <strong>Email: </strong>
                {email.email}
              </p>
            ))}

          {person?.documents &&
            person?.documents.length > 0 &&
            person?.documents.map((_document) => (
              <p key={_document.id}>
                <strong>Documento: </strong>
                {_document.type === "cpf"
                  ? MaskCPF(_document.number)
                  : _document.number}
              </p>
            ))}

          <p>
            <strong>Nome da mãe:</strong>
            {person.mother_name ? person.mother_name : "Não informado"}
          </p>
        </div>
      </div>
    </div>
  );
};
