import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";

import { Store } from "Reducers/interface";

import validatePermissions from "Utils/validatePermissions";

import LoadingImage from "Assets/Images/loading-purple.gif";

import { TabProps } from "../../interface";
import { ClientInfo } from "./ClientInfo";
import { OrderHistory } from "./OrderHistory";
import { LabiCredits } from "./LabiCredit";
import { OrderChart } from "./OrderChart";
import { useDashboard } from "./useDashBoard";

function DashboardTab({ person }: TabProps) {
  const params: any = useParams();
  const user_permissions = useSelector(
    (state: Store) => state.User.permissions
  );

  const {
    classification,
    handleCreateBudget,
    loading,
    recurrenceIcon,
    creditsData,
    dashData,
    recentOrders,
  } = useDashboard(person);

  if (loading) {
    return (
      <span className="loading-persons">
        <img src={LoadingImage} alt="" />
      </span>
    );
  }

  return (
    <div className="token-area">
      <div className="token-person-dashboard">
        <div className="person-dashboard-header">
          <div className="activity-label-dashboard">
            <img src={recurrenceIcon} alt="Recorrente" />

            <span>{classification?.toUpperCase() ?? "SEM CLASSIFICAÇÃO"}</span>
          </div>

          <div className="person-dashboard-header-buttons">
            {validatePermissions("Update Persons", user_permissions) && (
              <Link
                to={`/pessoa/${params.id}`}
                className="button _action _white"
              >
                Atualizar dados
              </Link>
            )}

            {validatePermissions("Update Persons", user_permissions) && (
              <button onClick={handleCreateBudget} className="button _action">
                Criar orçamento
              </button>
            )}
          </div>
        </div>

        <div className="dashboard-main-content">
          {person && <ClientInfo person={person} />}
          <LabiCredits creditsData={creditsData} />
        </div>

        <div className="dashboard-main-content">
          {recentOrders && <OrderHistory recentOrders={recentOrders} />}
          {dashData && <OrderChart dashData={dashData} />}
        </div>
      </div>
    </div>
  );
}

export default DashboardTab;
