import {
  GetPersonDashboard,
  getPersonalCreditsData,
  getPersonalDashboardData,
} from "Requests/Person";
import { useEffect, useState } from "react";
import LowFrequency from "Assets/Images/baixa.png";
import {
  DashboardDataProps,
  Person,
  RecentOrdersProps,
} from "Pages/Person/interface";
import Recurrent from "Assets/Images/recurrent.png";
import Inactive from "Assets/Images/inativo.png";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { selectPacient } from "Actions/ServiceForm";
import { GetOrdersBudgets } from "Requests/Budget";
import { Store } from "Reducers/interface";
import validatePermissions from "Utils/validatePermissions";
import { CreditsData } from "./interface";

export const useDashboard = (person: Person) => {
  const [loading, setLoading] = useState(false);
  const [classification, setClassification] = useState("");
  const [recurrenceIcon, setRecurrenceIcon] = useState(LowFrequency);
  const [creditsData, setCreditsData] = useState<CreditsData | null>(null);
  const [dashData, setDashData] = useState<DashboardDataProps>();
  const [recentOrders, setRecentOrders] = useState<RecentOrdersProps[]>([]);
  const user_permissions = useSelector(
    (state: Store) => state.User.permissions
  );

  const history = useHistory();
  const dispatch = useDispatch();

  const getDocument = () => {
    const cpf = person.documents.find(
      (item) => item.type.toUpperCase() === "CPF"
    );
    const passport = person.documents.find(
      (item) =>
        item.type.toUpperCase() === "PASSPORT" ||
        item.type.toUpperCase() === "PASSAPORTE"
    );

    return {
      cpf,
      passport,
    };
  };

  function handleCreateBudget() {
    dispatch(
      selectPacient({
        id: person.id,
        isPerson: true,
        birthdate: person?.birthdate,
        cpf: getDocument()?.cpf ?? "",
        first_name: person?.full_name,
        ddd: person?.phones[0]?.ddd ?? "",
        phone: person?.phones[0]?.phone ?? "",
        contact: person?.emails[0]?.email ?? "",
        passport: getDocument().passport ?? null,
      })
    );

    history.push("/ficha-de-atendimento/person/selecionar-produtos/undefined");
  }
  const handleCreditMessage = (message: string) => {
    if (message.includes("Usuário não possui conta no site Labi")) {
      return "Este cliente ainda não possui conta no site do Labi.";
    }

    if (
      message.includes(
        "Não existe movimentação Labi Créditos para esse usuário"
      )
    ) {
      return "Este cliente ainda não utilizou o programa Labi Créditos!";
    }
  };

  async function fetchOrderBudgets() {
    try {
      if (validatePermissions("Consult Budgets", user_permissions)) {
        const resultsObject = await GetOrdersBudgets(person.id);

        setRecentOrders(resultsObject.data);
      }
    } catch (error) {
      console.log({ error });
    }
  }

  const fetchDashFlag = async () => {
    try {
      const { data } = await GetPersonDashboard(person.id);
      const icon =
        data.classification === "Recorrente"
          ? Recurrent
          : data.classification === "Inativo"
          ? Inactive
          : LowFrequency;

      setClassification(data.classification);
      setRecurrenceIcon(icon);
    } catch (error) {
      console.log(error);
    }
  };

  async function fetchDashData() {
    try {
      const { data } = await getPersonalDashboardData(person.id);

      setDashData(data);
    } catch (error) {
      console.log(error);
    }
  }

  async function fetchCreditsData() {
    try {
      const personCredits = await getPersonalCreditsData(person.id);

      if (personCredits.message) {
        const creditMessage = handleCreditMessage(personCredits.message);

        setCreditsData({ credits: null, creditsMessage: creditMessage });
        return;
      }

      const [data] = personCredits;

      setCreditsData({ credits: data, creditsMessage: "" });
    } catch (error) {
      console.log(error);
    }
  }

  const loadInitialDatas = async () => {
    try {
      setLoading(true);

      await Promise.allSettled([
        fetchOrderBudgets(),
        fetchDashFlag(),
        fetchDashData(),
        fetchCreditsData(),
      ]);
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadInitialDatas();
  }, []);

  return {
    handleCreateBudget,
    loading,
    classification,
    recurrenceIcon,
    creditsData,
    dashData,
    recentOrders,
  };
};
