import { DashboardDataProps } from "Pages/Person/interface";
import Chart from "react-apexcharts";

const chartOptions = {
  colors: ["#AB92BF", "#815CA0", "#572580", "#fff45b"],
  legend: {
    show: false,
  },
  labels: ["Exames", "Testes", "Check-ups", "Vacinas"],
  dataLabels: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      expandOnClick: true,

      donut: {
        labels: {
          show: true,

          name: {
            show: false,
          },

          total: {
            show: true,

            showAlways: true,
          },
        },
      },
    },
  },
};

interface OrderChartProps {
  dashData: DashboardDataProps;
}

export const OrderChart = ({ dashData }: OrderChartProps) => {
  const defaultSeries = [0, 0, 0, 0];

  const hasOrders =
    dashData?.exams ||
    dashData?.tests ||
    dashData?.check_ups ||
    dashData?.vaccines;

  const totalProductsBought = [
    dashData?.exams,
    dashData?.tests,
    dashData?.check_ups,
    dashData?.vaccines,
  ];

  const series = hasOrders ? totalProductsBought : defaultSeries;

  const showChart = series.some((item) => item > 1);

  return (
    <div className="dashboard-general-history">
      <span className="dashboard-sub-title">HISTÓRICO GERAL</span>

      {series && showChart ? (
        <div className="dashboard-general-history-legends">
          <Chart
            options={chartOptions}
            series={series}
            type="donut"
            width={250}
          />

          <div className="chart-legends">
            <div>
              <p>TOTAL SERVIÇOS REALIZADOS</p>
              <span>{dashData?.total || 0}</span>
            </div>

            <div>
              <div>
                <p>EXAMES</p>
                <span>{dashData?.exams || 0}</span>
              </div>

              <div>
                <p>TESTES</p>
                <span>{dashData?.tests || 0}</span>
              </div>

              <div>
                <p>CHECK-UPS</p>
                <span>{dashData?.check_ups || 0}</span>
              </div>

              <div>
                <p>VACINAS</p>
                <span>{dashData?.vaccines || 0}</span>
              </div>
            </div>

            <div className="chart-legends-tickets">
              <div>
                <p>TICKET MÉDIO</p>
                <span>R$ {dashData?.average_spent || "0,00"}</span>
              </div>

              <div>
                <p>TOTAL GASTO ATÉ HOJE</p>
                <span>R$ {dashData?.total_spent || "0,00"}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <p> Informação indisponível no momento</p>
      )}

      <div className="dashboard-customer-since">
        <span>CLIENTE LABI DESDE: {dashData?.client_since}</span>
      </div>
    </div>
  );
};
