import React from "react";

import CurrencyInput from "./rootInputCurrency";

const InputCurrency = ({
  value,
  onChange,
  label,
  error,
  small,
  id,
  purple,
  required,
  placeholder,
  readOnly,
  description,
  onBlur,
  name,
}) => {
  return (
    <div
      className={`
            field-holder 
            ${error ? "field-holder-invalid" : ""}
            ${small ? "field-holder-small" : ""}
        `}
    >
      {label && (
        <label
          htmlFor={id}
          className={`text-gray text-semibold ${purple ? "_purple" : ""}`}
        >
          {label}
          {description && (
            <div className="tooltip-holder">
              <span className="tooltip-icon">
                <i className="icon-help" />
              </span>
              <div className="tooltip-description">{description}</div>
            </div>
          )}
        </label>
      )}
      <CurrencyInput
        id={id}
        className="input _opacity-gray"
        required={required ? "required" : ""}
        placeholder={placeholder}
        readOnly={readOnly}
        value={value}
        onBlur={onBlur}
        name={name}
        onChangeEvent={(event, maskedvalue, floatvalue) => {
          event.preventDefault();

          onChange({
            target: {
              id,
              value: floatvalue,
            },
          });
        }}
        decimalSeparator=","
        thousandSeparator="."
        prefix="R$ "
      />
      {error !== "" && <span className="error-message">{error}</span>}
    </div>
  );
};

export default InputCurrency;
