import { Field, useFormikContext } from "formik"
import Input from "Components/Form/Input";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import InputCurrencyControlled from "Components/Form/Controlled/InputCurrencyControlled";
import { useEffect } from "react";
import { useBudgetOrderLocalStorage } from "Stores/budget-order";
import { calculateAmount, sumAmount } from "Pages/Orders/CreateOrder/components/functions";
import { PaymentOptionType } from "Pages/Orders/CreateOrder/components/PaymentTypes/types";
import { MoneyTypeProps } from "./types";

export const MoneyType = ({ values, currentIndexPayment }: MoneyTypeProps) => {

  const { order, setOrder, currentPaymentType } = useBudgetOrderLocalStorage();

  const { setValues, setFieldError, errors, setErrors } = useFormikContext();

  useEffect(() => {
    if (currentPaymentType[currentIndexPayment] === 'money') {
      if (values.money.received) {

        const clonnedPaymentInfo = [...order.payment_info];
        clonnedPaymentInfo[currentIndexPayment] = {
          ...order.payment_info[currentIndexPayment],
          money: {
            ...values.money,
          }
        }

        setDataInsideStorage(clonnedPaymentInfo)
      }
    }

  }, [values.money.received])

  const setDataInsideStorage = (payment_info: PaymentOptionType[]) => {
    setOrder({
      ...order,
      payment_info
    })
  }

  const handleReceivedBlur = () => {
    const total = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);
    const verification = calculateAmount(currentPaymentType, order.payment_info, total);

    if (verification.isLowerTotal || verification.sameValue) {
      const amountSum = sumAmount(currentPaymentType, order.payment_info)
      
      const remain = ((order.subtotal - order.discounts)  - amountSum) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);
      const change = values.money.received - values.money.total;

      setValues({
        ...values,
        money: {
          ...values.money,
          remain: remain < 0 ? 0 : remain,
          change: change < 0 ? 0 : change
        }
      });


      const clonnedPaymentInfo = [...order.payment_info];
      clonnedPaymentInfo[currentIndexPayment] = {
        ...order.payment_info[currentIndexPayment],
        money: {
          ...values.money,
          total: values.money.total,
          received: values.money.received,
          remain,
          change
        }
      }

      setDataInsideStorage(clonnedPaymentInfo)

      setFieldError('money.received', '');
      const updatedErrors: any = { ...errors };
      delete updatedErrors?.money;
      return setErrors(updatedErrors);
    }


  }

  return (
    <>
      <div
        style={{ width: "100%", marginTop: "1em", display: "flex", flexDirection: "row", gap: 20 }}
      >
        <div style={{
          width: "33%",
        }}>
          <Field
            name="money.received"
            id="money.received"
            type="text"
            label="Valor"
            placeholder="Valor"
            value={values.money.received}
            component={InputCurrencyControlled}
            onBlur={() => handleReceivedBlur()}
          />
        </div>
        <div style={{
          width: "33%",
        }}>
          <Field
            name="money.remain"
            id="money.remain"
            type="text"
            label='Restante'
            placeholder="Restante"
            value={replaceStringNumber(values.money.remain)}
            component={Input}
            readOnly
          />
        </div>

        <div style={{
          width: "33%",
        }}>
          <Field
            name="money.change"
            id="money.change"
            type="text"
            label='Troco'
            placeholder="Troco"
            value={replaceStringNumber(values.money.change)}
            component={Input}
            readOnly
          />
        </div>
      </div>
    </>
  )
}