import moment from "moment";

moment.updateLocale("pt-BR", {
  week: {
    dow: 1,
  },
});

const formatDay = (list_day, type) => {
  const daysToChart = {
    labels: [],
    success: [],
    errors: [],
  };

  Object.keys(list_day).forEach((key) => {
    daysToChart.labels.push(moment(key).format("DD/MM/YYYY"));
    daysToChart.success.push(
      type === "rnds" ? list_day[key].sent || 0 : list_day[key].success || 0
    );
    daysToChart.errors.push(list_day[key].errors || 0);
  });

  return daysToChart;
};

const formatWeek = (list_week, type) => {
  const weeksToChart = {
    labels: [],
    success: [],
    errors: [],
  };

  list_week.forEach((week) => {
    const { year } = week;

    week.list.forEach((weekList) => {
      const startWeek = moment()
        .year(year)
        .week(weekList.week)
        .startOf("week")
        .format("DD/MM");

      const endWeek = moment()
        .year(year)
        .week(weekList.week)
        .endOf("week")
        .format("DD/MM/YY");

      const w = `${startWeek} a ${endWeek}`;

      weeksToChart.labels.push(w);
      weeksToChart.success.push(
        type === "rnds" ? weekList.sent || 0 : weekList.success || 0
      );
      weeksToChart.errors.push(weekList.errors || 0);
    });
  });

  return weeksToChart;
};

const formatMonth = (list_month, type) => {
  const monthsToChart = {
    labels: [],
    success: [],
    errors: [],
  };

  list_month.forEach((month) => {
    const { year } = month;

    month.list.forEach((monthList) => {
      const date = moment()
        .year(year)
        .month(monthList.month - 1)
        .format("DD/MM/YYYY");
      monthsToChart.labels.push(date);
      monthsToChart.success.push(
        type === "rnds" ? monthList.sent || 0 : monthList.success || 0
      );
      monthsToChart.errors.push(monthList.errors || 0);
    });
  });

  return monthsToChart;
};

export function transformResponseToChartData(response, type, dates) {
  const { list_day, list_week, list_month } = response;

  const daysToChart = formatDay(list_day, type);

  const weeksToChart = formatWeek(list_week, type);

  const monthsToChart = formatMonth(list_month, type);

  return {
    daysToChart,
    weeksToChart,
    monthsToChart,
  };
}
