import { OrderType } from "Stores/budget-order"
import { PaymentOptionType } from "./PaymentTypes/types";

export type CalculateTotal = {
  isLowerTotal: boolean;
  sameValue: boolean;
}


export const cleanBudgetOrderStorage = () => {
  const data: OrderType = {
    budget_id: 0,
    budget_type: 1,
    appointment_type_id: "",
    isTaxInserted: false,
    payment_info: [],
    payer_patient: {
      id: 0,
      birthdate: '',
      document: {
        id: 0,
        number: '',
        type: '',
        country_id: 0
      },
      email: '',
      gender_id: 0,
      mother_name: '',
      name: '',
      phone: {
        id: 0,
        ddd: 0,
        ddi: 0,
        phone: 0
      },
      unborn: false,
      social_name: ''
    },
    lead: {
      id: 0,
      ddd: null,
      phone: '',
      email: null,
      name: '',
    },
    attendances: [],
    itemsQuantity: 0,
    totalPrice: '0,00',
    home_collected: false,
    coupon_percentage_discount: 0,
    homeCollectValue: 0,
    isCovenant: false,
    discounts: 0,
    subtotal: 0,
    deliveryTime: '',
    items: [],
    product_categories: [],
    formattedAttendances: [],
    session_start: "",
    session_end: "",
    lastAddress: {
      street: "",
      number: "",
      uf: "",
      neighborhood: "",
      city: "",
      channel_id: 4,
      complement: '',
      country_id: 26,
      default: true,
      reference_point: '0',
      type_id: 1,
      zip_code: ''
    },
    attendanceLabel: [],
    dependents: [],
    patients: [],
    patientWithProducts: [],
    cupon_code: '',
    home_collect_value: '',
    orderData: {
      order_type: 1,
      channel_id: 4,
      commercial_category: 63,
      appointment_type_id: "",
      commercial_document_number: '',
      payer_id: 0,
      order: {
        budget_id: 0,
        subtotal: '',
        discounts: '',
        total: 0,
        coupon_code: null,
        home_collected: false,
        home_collect_value: '',
        home_collect_date: '',
        vaccine_cards: [],
        cart: [],
        home_collect_schedule: {
          id_home_collected: 0,
          home_collect_date: "",
          zip_code: "",
          country: "Brasil",
          uf: "",
          city: "",
          neighborhood: "",
          street: "",
          number: "",
          complement: "",
          reference_point: "",
          address_type_id: 1,
        }
      }
    }
  }

  return data
}

export const calculateAmount = (
  currentPaymentType: string[],
  payment_info: PaymentOptionType[],
  subtotal: number
): CalculateTotal => {
  const sum = currentPaymentType.reduce((total, paymentType, index) => {
    // Ignorar o tipo "link"
    if (paymentType === "link") return total;

    // Verifica se existe um objeto no índice correspondente
    const paymentData = payment_info[index]?.[paymentType as keyof PaymentOptionType];

    // Soma o valor de 'received', caso exista
    return total + (paymentData?.received || 0);
  }, 0);

  // Caso soma de todos os valores for menor que o total do pedido.

  // return sum < subtotal;
  return {
    isLowerTotal: sum < subtotal,
    sameValue: sum === subtotal
  }
};

export const sumAmount = (
  currentPaymentType: string[],
  payment_info: PaymentOptionType[],
): number => {
  const sum = currentPaymentType.reduce((total, paymentType, index) => {
    // Ignorar o tipo "link"
    if (paymentType === "link") return total;

    // Verifica se existe um objeto no índice correspondente
    const paymentData = payment_info[index]?.[paymentType as keyof PaymentOptionType];

    // Soma o valor de 'received', caso exista
    return total + (paymentData?.received || 0);
  }, 0);

  return sum

}
