  import styled from "styled-components";

  export const Container = styled.div`
    box-shadow: 0 0 0 1px rgba(130, 136, 148, 0.16),
      0 1px 2px -1px rgba(130, 136, 148, 0.48);
    margin-bottom: 16px;
    border-radius: 24px;
    overflow: hidden;
    position: relative;
    transition: height 0.6s;

    .budgeting-header {
      padding: 25px 32px 26px;
      box-sizing: border-box;

      .info-item {
        margin-bottom: 30px;
        text-align: center;

        .label-collect-wrapper {
          text-align: right;
          margin-bottom: 0 !important;
        }

        .text-through {
          text-decoration: line-through;
        }

        .text-lgray {
          color: #999;
        }

        .text-no-margin {
          margin-bottom: 0;
        }

        .text-left {
          text-align: left;
        }

        .text-center {
          text-align: center;
        }

        p {
          &:last-child {
            font-weight: 500;
          }
        }
      }
    }

    &:before {
      content: "";
      display: block;
      width: 12px;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      background-color: #ab92bf;
      transition: none !important;
    }

    &:last-child {
      margin-bottom: 0;
    }

    &.collect-cards {
      font-family: "Saira", sans-serif;
      max-width: 100%;
      margin-bottom: 0;
      border-radius: 0;
      background-color: white;
      cursor: pointer;
      overflow: visible;

      &:hover {
        background-color: #F5F5F5;
      }

      &::before {
        content: "";
        display: block;
        width: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: #ab92bf;
        transition: all 0.3s ease-in-out;
      }

      &:first-child {
        border-top-left-radius: 24px;
        border-top-right-radius: 24px;

        &::before {
          border-top-left-radius: 48px;
          top: auto;
          bottom: 0;
        }
      }

      &:last-child {
        border-bottom-left-radius: 24px;
        border-bottom-right-radius: 24px;

        &::before {
          border-bottom-left-radius: 48px;
        }
      }

      &._selected {
        // background-color: #f5f5f5;

        &::before {
          // border-top-left-radius: 24px;
          // margin-left: 1px;
          width: 8px;
          height: 100%
        }
      }

      &._inactive {
        cursor: not-allowed;
        position: relative;
      }

      .budgeting-header {
        display: flex;
        justify-content: space-between;
        align-content: center;
        padding: 30px 25px;
        // background-color: white;

        .info-item {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 0;

          .text-info-title {
            padding: 0 15px;
          }

          figure {
            margin: 0;
            padding: 0;
            min-width: 52px;
          }

          @media (min-width: 1024px) {
            flex: none;
            max-width: none;
          }
        }
      }
    }

    @media screen {
      @media (min-width: 480px) {
        &.card-orders-item {
          .budgeting-header {
            .info-wrapper {
              .info-item {
                p {
                  font-size: 14px;
                }

                &.info-date {
                  p {
                    &:first-child {
                      font-size: 14px;
                      margin-bottom: 0 !important;
                      line-height: 17px;
                    }

                    &:last-child {
                      font-size: 20px;
                      line-height: 31px !important;
                    }
                  }
                }
              }
            }
          }
        }
      }

      @media (min-width: 768px) {
        .budgeting-header {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .info-item {
            margin-bottom: 0;

            p {
              &:first-child {
                margin-bottom: 9px;
              }
            }

            &.info-number {
              text-align: left;

              p {
                &:first-child {
                  margin-bottom: 3px;
                }
              }
            }
          }
        }
      }

      @media (min-width: 1024px) {
        .budgeting-header {
          .info-item {
            flex: 0 0 20%;
            max-width: 20%;

            &.info-number {
              max-width: 15%;
            }
          }
        }
      }
    }

  `;
