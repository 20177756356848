import Axios from "axios";
import { getToken } from "Services/Authentication";




export const fetchProductRules = async (products_ids: string) => {

  const API = Axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}`,
    headers: {
      "Content-Type": "application/json",
      "Authorization": `Bearer ${getToken()}`
    },
  });

  try {
    const response = await API.get(`/products/product_rules/${products_ids}`, {
    });

    return response.data;
  } catch (err) {
    console.error(err);
    throw "Ops! Ocorreu um erro ao buscar as regras do produto";
  }
}