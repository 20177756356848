import React from "react";

import Topbar from "Containers/Nav/Topbar";
import ListCLinicsBudgets from "Pages/Budgets/ClinicsBudgets";
import { CovenantsBudgets } from "Pages/Budgets/Covenants";
import { PrivateBudgets } from "Pages/Budgets/Private";
import { Reception } from "Pages/Budgets/Reception/index";
import { RevisionBudget } from "Pages/Budgets/RevisionBudget/index";
import { ViewBudget } from "Pages/Budgets/ViewDetails";
import ListCoupons from "Pages/Coupon/Index";
import Coupon from "Pages/Coupon/View";
import { OrderDashboard } from "Pages/Dashboard/Order";
import { ResultsDashboard } from "Pages/Dashboard/Results";
import { RNDSDashboard } from "Pages/Dashboard/RNDS";
import { HealthInsurance } from "Pages/HealthInsurance";
import { CreateHealthInsurance } from "Pages/HealthInsurance/CreateHealthInsurance";
import { EditHealthInsurance } from "Pages/HealthInsurance/EditHealthInsurance";
import HomeOrdersTelemedicina from "Pages/HomeOrders/ExportTelemedicina";
import HomeOrdersToMotion from "Pages/HomeOrders/ExportToMotion";
import ProductLogs from "Pages/Logs/productLogs";
import ImportOrders from "Pages/Orders/Import";
import ListOrders from "Pages/Orders/Index";
import ListPlansOrders from "Pages/Orders/ListPlansOrders/Index";
import OrderToken from "Pages/Orders/Token";
import { ListPersons } from "Pages/Person";
import Person from "Pages/Person/Show";
import PersonForm from "Pages/Person/Token";
import { ViewOrder } from "Pages/Person/TokenTabs/ViewOrder";
import { PersonDuplicated } from "Pages/PersonDuplicated";
import { CreateNewProduct } from "Pages/Product/CreateProduct";
import { EditProduct } from "Pages/Product/EditProduct";
import ListProduct from "Pages/Product/Index";
import { RegistrationCleaning } from "Pages/RegistrationCleaning";
import { RegistrationReview } from "Pages/RegistrationReview";
import ListRNDS from "Pages/RNDS/Index";
import { ClinicalSchedule } from "Pages/Schedules/ClinicalSchedule";
import FinishRegisterForm from "Pages/ServiceForm/LeadToPerson/index";
import ServiceFormOrderList from "Pages/ServiceForm/OrderList";
import ServiceFormPayment from "Pages/ServiceForm/Payment";
import PaymentFormsSelect from "Pages/ServiceForm/PaymentForms";
import ServiceFormResume from "Pages/ServiceForm/Resume";
import ServiceAttendanceType from "Pages/ServiceForm/SelectAttendance";
import ServiceFormSelectBudget from "Pages/ServiceForm/SelectBudgetType";
import ServiceFormPacient from "Pages/ServiceForm/SelectPacient";
import ServiceFormSelectProducts from "Pages/ServiceForm/SelectProducts";
import ServiceUnitSelect from "Pages/ServiceForm/SelectUnit";
import ListUsers from "Pages/Users/Index";
import Users from "Pages/Users/View";

import Sidebar from "Components/Nav/Sidebar";

import { CreateOrderAttendance } from "Pages/Orders/CreateOrder/components/Collect";
import { CreateOrderIdentification } from "Pages/Orders/CreateOrder/components/Patient";
import { CreateOrderPayment } from "Pages/Orders/CreateOrder/components/Payment";
import { PaymentTypes } from "Pages/Orders/CreateOrder/components/PaymentTypes";
import ReportLog from "Pages/ReportLog";
import HomeOrders from "../../Pages/HomeOrders";
import LabiCredits from "../../Pages/LabiCredits";
import LabiWallet from "../../Pages/LabiCredits/LabiWallet";
import MyAccountLabiCredits from "../../Pages/LabiCredits/MyAccountLabiCredits";
import Salesforce from "../../Pages/Logs/Salesforce";
import { SendMotion } from "../../Pages/Orders/SendMotion";
import ListSchedule from "../../Pages/Schedules/Index";
import { ListAlerts } from "../../Pages/Units/Alerts";
import { Units } from "../../Pages/Units/Index";
import { ListRegions } from "../../Pages/Units/Regions/Index";
import { CreateProduct } from "../../Pages/Units/View";


export default [
  {
    component: ListPersons,
    path: "/pessoas",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Persons",
    footer: false,
    exact: true,
  },
  {
    component: ListPersons,
    path: "/clientes",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Persons",
    footer: false,
    exact: true,
  },
  {
    component: PersonDuplicated,
    path: "/pessoas-duplicadas",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Persons",
    footer: false,
    exact: true,
  },
  {
    component: Person,
    path: "/pessoa/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Persons", "Update Persons"],
    footer: false,
    exact: true,
  },
  {
    component: PersonForm,
    path: "/ficha-cliente/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Persons",
    footer: false,
    exact: true,
  },
  {
    component: ViewOrder,
    path: "/ficha-cliente/:personId/appointments/:orderId",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Persons",
    footer: false,
    exact: true,
  },

  {
    component: ListProduct,
    path: "/produtos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Products",
    footer: false,
    exact: true,
  },
  {
    component: CreateNewProduct,
    path: "/produtos/novo",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Create Products",
    footer: false,
    exact: true,
  },
  {
    component: EditProduct,
    path: ["/produto/:productId/editar", "/produto/:duplicar/:productId"],
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Create Products",
    footer: false,
    exact: true,
  },
  {
    component: ProductLogs,
    path: "/log-produtos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Create Products",
    footer: false,
    exact: true,
  },

  {
    component: Units,
    path: "/unidades",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "List Units",
    footer: false,
    exact: true,
  },

  {
    component: CreateProduct,
    path: "/unidades/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Units", "Update Units"],
    footer: false,
    exact: true,
  },

  {
    component: HealthInsurance,
    path: "/convenios",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Units", "Update Units"],
    footer: false,
    exact: true,
  },

  {
    component: CreateHealthInsurance,
    path: "/convenios/novo",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Units", "Update Units"],
    footer: false,
    exact: true,
  },
  {
    component: EditHealthInsurance,
    path: "/convenios/editar/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Units", "Update Units"],
    footer: false,
    exact: true,
  },

  {
    component: ListRegions,
    path: "/regioes",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Units", "Update Units"],
    footer: false,
    exact: true,
  },

  {
    component: ListAlerts,
    path: "/alertas",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Units", "Update Units"],
    footer: false,
    exact: true,
  },
  {
    component: ListOrders,
    path: "/pedidos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },
  {
    component: CreateOrderAttendance,
    path: "/pedidos/criar-pedido/coleta",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },
  {
    component: CreateOrderIdentification,
    path: "/pedidos/criar-pedido/identificacao",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },
  {
    component: CreateOrderPayment,
    path: "/pedidos/criar-pedido/pagamento",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },
  {
    component: PaymentTypes,
    path: "/pedidos/criar-pedido/tipo-pagamento",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },
  // {
  //   component: CreateOrder,
  //   path: "/pedidos/criar-pedido",
  //   header: <Topbar />,
  //   sidebar: <Sidebar />,
  //   permissions: "Consult Orders",
  //   footer: false,
  //   exact: true,
  // },
  {
    component: ListPlansOrders,
    path: "/pedidos-convenios",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },

  {
    component: HomeOrders,
    path: "/pedidos-domiciliar",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Send Orders to Motion",
    footer: false,
    exact: true,
  },
  {
    component: ImportOrders,
    path: "/pedidos/importar",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Import Orders",
    footer: false,
    exact: true,
  },
  {
    component: HomeOrdersToMotion,
    path: "/pedidos-domiciliar/motion",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Send Orders to Motion",
    footer: false,
    exact: true,
  },
  {
    component: OrderToken,
    path: "/ficha-de-pedido/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Orders",
    footer: false,
    exact: true,
  },

  {
    component: SendMotion,
    path: "/pedidos-envio/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Send Orders to Motion",
    footer: false,
    exact: true,
  },
  {
    component: ListCoupons,
    path: "/cupons",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Coupons",
    footer: false,
    exact: true,
  },
  {
    component: ReportLog,
    path: "/controle-visualizacao",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Coupons",
    footer: false,
    exact: true,
  },
  {
    component: Coupon,
    path: "/cupons/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Coupons", "Update Coupons"],
    footer: false,
    exact: true,
  },

  {
    component: PrivateBudgets,
    path: "/orcamentos/particular",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Budgets",
    footer: false,
    exact: true,
  },
  {
    component: CovenantsBudgets,
    path: "/orcamentos/convenio",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Budgets",
    footer: false,
    exact: true,
  },
  {
    component: ViewBudget,
    path: "/orcamento/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Budgets",
    footer: false,
    exact: true,
  },

  {
    component: ListCLinicsBudgets,
    path: "/orcamentos/clinicas",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Budgets",
    footer: false,
    exact: true,
  },

  {
    component: Reception,
    path: "/orcamento/pre-acolhimento/:person_type/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Budgets",
    footer: false,
    exact: true,
  },

  {
    component: RegistrationCleaning,
    path: "/limpeza-cadastro/:document/:step",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Edit Persons",
    footer: false,
    exact: true,
  },

  {
    component: ListSchedule,
    path: "/agendamentos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: [
      "List Appointments",
      "Show Appointments Calendars",
      "Show Appointments Historic",
      "List Date and Time for Appointments",
      "Reschedule Appointments",
      "Cancel Appointments",
      "Update Appointment",
    ],
    footer: false,
    exact: true,
  },

  {
    component: LabiCredits,
    path: "/labi-creditos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "List Wallet Credits and Movimentations",
    footer: false,
    exact: true,
  },

  {
    component: LabiWallet,
    path: "/labi-creditos/minha-conta",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "List Wallet Credits and Movimentations",
    footer: false,
    exact: true,
  },

  {
    component: MyAccountLabiCredits,
    path: "/labi-creditos/minha-conta/:userID",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "List Wallet Credits and Movimentations",
    footer: false,
    exact: true,
  },

  {
    component: ServiceFormPacient,
    path: "/ficha-de-atendimento/paciente",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: ServiceAttendanceType,
    path: "/ficha-de-atendimento/:person_type/tipo-de-atendimento",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: ServiceFormSelectBudget,
    path: "/ficha-de-atendimento/:person_type/tipo-de-orcamento",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: ServiceFormOrderList,
    path: "/ficha-de-atendimento/:type/orcamentos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: ServiceFormSelectProducts,
    path: [
      "/ficha-de-atendimento/:person_type/selecionar-produtos/:budget_id",
      "/ficha-de-atendimento/:person_type/selecionar-produtos",
    ],
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: ServiceUnitSelect,
    path: "/ficha-de-atendimento/:person_type/:budget_id/selecionar-unidade",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },

  {
    component: FinishRegisterForm,
    path: "/ficha-de-atendimento/finalizar-cadastro/:budget_id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },

  {
    component: ServiceFormResume,
    path: "/ficha-de-atendimento/resumo/:budget_id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Budgets",
    footer: false,
    exact: true,
  },
  {
    component: ServiceFormPayment,
    path: "/ficha-de-atendimento/pagamento/:budget_id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },

  {
    component: ListUsers,
    path: "/usuarios",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult Users",
    footer: false,
    exact: true,
  },
  {
    component: Users,
    path: "/usuarios/:id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Users", "Update Users"],
    footer: false,
    exact: true,
  },
  {
    component: RegistrationReview,
    path: "/revisao/usuario",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Users", "Update Users"],
    footer: false,
    exact: true,
  },

  {
    component: ListRNDS,
    path: "/rnds",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },

  {
    component: Salesforce,
    path: "/salesforce",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },

  {
    component: OrderDashboard,
    path: "/dashboard/pedidos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },

  {
    component: RNDSDashboard,
    path: "/dashboard/rnds",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },
  {
    component: ResultsDashboard,
    path: "/dashboard/resultados",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },
  {
    component: PaymentFormsSelect,
    path: "/ficha-de-atendimento/clinicas/pagamento/:budget_id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: RevisionBudget,
    path: "/pedido/revisar/:order_id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: ClinicalSchedule,
    path: "/pedido/agendar/:order_id",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: ["Create Budgets", "Update Budgets"],
    footer: false,
    exact: true,
  },
  {
    component: OrderDashboard,
    path: "/dashboard/orders",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },

  {
    component: RNDSDashboard,
    path: "/dashboard/laudos",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },
  {
    component: ResultsDashboard,
    path: "/dashboard/results",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Consult RNDS",
    footer: false,
    exact: true,
  },
  {
    component: HomeOrdersToMotion,
    path: "/pedidos-domiciliar/motion",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Send Orders to Motion",
    footer: false,
    exact: true,
  },
  {
    component: HomeOrdersTelemedicina,
    path: "/pedidos/telemedicina",
    header: <Topbar />,
    sidebar: <Sidebar />,
    permissions: "Send Orders to Motion",
    footer: false,
    exact: true,
  },
];
