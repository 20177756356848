import axios from "axios";
import { getToken } from "Services/Authentication";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_API_URL;

export async function getCardOperators() {
  try {

    const data = await axios.get(`${BASE_URL}/orders/card/operators`, {
      headers: {
        authorization: `Bearer ${getToken()}`,
      }
    })

    return data.data
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;

      // Tratamento de erro 404
      if (status === 404) {
        toast.error(data.message);
      }

      // Tratamento de erro 500
      if (status === 500) {
        toast.error(
          "Ocorreu ao buscar as bandeiras do cartão."
        );
      }
    }
  }
}