import { Box } from "styles/globalComponents";
import Success from "Assets/Images/successIcon.svg";
import { Modal } from "Components/Modals/Modal";
import { PaymentLinkButton } from "Components/Form/PaymentLinkButton";
import { CopyToClipboard } from "Utils/Copy";
import { Button } from "Components/Button";
import { useHistory } from "react-router-dom";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";

interface PaymentConfirmationModalProps {
  isOpen: boolean;
  closeModal: () => void;
  paymentLink: string;
  orderId: string;
  orderPrice: string;
}

export const PaymentConfirmationModal = ({
  isOpen,
  closeModal,
  paymentLink,
  orderId,
  orderPrice
}: PaymentConfirmationModalProps) => {

  const history = useHistory();

  const copyLink = (link: string) => {
    CopyToClipboard({ message: "Copiado!", text: link });
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      closeIcon={false}
      renderHeading={() => (
        <h2 style={{ textAlign: 'center' }}>
          {" "}
          {paymentLink ? ' Link de pagamento criado com sucesso!' : 'Pedido criado com sucesso!'}
        </h2>
      )}
      renderDescription={() => (
        <>
          {paymentLink ? <p style={{ marginBottom: '-1.5em' }}>
            O link para pagamento no valor de <strong>R$ {orderPrice} </strong> foi criado com sucesso.
            Agora ele só precisa realizar o pagamento via checkout remoto para confirmar o seu pedido:
          </p> : <p style={{ marginBottom: '-1.5em' }}>
            O pedido no valor de <strong>R$ {replaceStringNumber(Number(orderPrice))} </strong> foi criado com sucesso.
          </p>}
        </>
      )}
      renderActions={() => (
        <Box display="flex" direction="column">

          {paymentLink ? <PaymentLinkButton
            placeholder="Email do paciente"
            value={paymentLink}
            label="COPIAR LINK"
            sendCopy={(link) => copyLink(link)}
            minHeight="3em"
          />
            : <></>}

          {paymentLink ? <p style={{
            marginBottom: '-1.5em',
            color: 'var(--color-hgray-text)',
            textAlign: 'center',
            fontFamily: 'var(--font-text)',
            fontSize: '16px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '160%',
            margin: '0px',
          }}>
            <strong> Importante:</strong> Após o pagamento, o pedido será confirmado pelo sistema e
            o preparo para os exames será enviado automaticamente para o
            email do paciente.
          </p>
            : <></>}

          <Button width="full" minHeight="3.7em" fontWeight="600" onClick={() => history.push('/pedidos')}>
            voltar para pedidos
          </Button>

          <Button width="full" outlined minHeight="3.7em" fontWeight="600" onClick={() => history.push(`/ficha-de-pedido/${orderId}`)}>
            VER DETALHES DO PEDIDO
          </Button>

          <Button width="full" textButton fontWeight="600" onClick={() => history.push('/ficha-de-atendimento/paciente')}>
            criar novo orçamento
          </Button>
        </Box >
      )}
    />
  )

}