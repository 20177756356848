import styled, { css } from "styled-components";

interface ContainerAccordionProps {
  padding?: string;
  pl?: string;
  pr?: string;
  pb?: string;
  pt?: string;
}

export const ContainerAccordion = styled.section.attrs(
  ({ padding }: { padding: string }) => ({
    padding: padding || "16px 31px",
  })
)<ContainerAccordionProps>`
  ${({ padding }) =>
    css`
      padding: ${padding};
    `}

  height:fit-content;
  align-self: flex-start;
  // background: #F5F5F5;
  background: #FFFFFF
  
  border: none;
  box-sizing: border-box;
  // border-radius: 16px;

  min-width: 215px;
  width: 100%;
  border-top: 2px solid #EAEBED;
`;