import { useState } from "react";
import { ContentAccordion } from "Components/AccordionCollapse/style";
import { ContainerAccordion } from "./styles";
import { PaymentAccordionProps } from "./types";



export const PaymentAccordion = ({ children, cssClass, isOpen }: PaymentAccordionProps) => {

  const [open, setOpen] = useState(isOpen);

  return (
    <ContainerAccordion
      className={cssClass}
    >
      {open && <ContentAccordion>{children}</ContentAccordion>}
    </ContainerAccordion>
  )

}