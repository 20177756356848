import { CSSProperties, useEffect, useState } from "react";
import { BudgetMotion, BudgetMotionParams } from "Dtos/IMotion";
import { MotionModal } from "Pages/ServiceForm/components/Cart/CartActions/Modals/Motion";
import { MotionSuccessModal } from "Pages/ServiceForm/components/Cart/CartActions/Modals/MotionSuccess";
import { Box } from "styles/globalComponents";

import { addGeneralInfoAndDocuments } from "Requests/Budget";
import { CreateImgCovenant, updateCovenantCredentials } from "Requests/Person";

import { CopyToClipboard } from "Utils/Copy";
import { createToast } from "Utils/toastFunc";

import { Button } from "Components/Button";
import { SendEmailButton } from "Components/Form/SendEmailButton";
import { Modal } from "Components/Modals/Modal";
import { CovenantSendParams } from "Components/ServiceForm/ModalsClientCovenant/interface";

import Success from "Assets/Images/successIcon.svg";

import { OrderType, useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { useShallow } from "zustand/react/shallow";
import { mountAttendances, mountCartItems } from "Pages/ServiceForm/components/Cart/CartActions/Modals/Success/utils";
import { useHistory } from "react-router";
import { CompleteLeadRegisterModal } from "Pages/ServiceForm/components/Cart/CartActions/Modals/CompleteLeadRegister";
import { IncompleteRegisterModal } from "Pages/ServiceForm/components/Cart/CartActions/Modals/IncompleteRegister";
import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { ModalAlertProps } from "./interface";
import { ModalCovenantMotion } from "../Motion/CovenantIntegration";


export const BudgetActionsModals = ({
  handlePdfDownload,
  modalOpen,
  motionIntegration,
  onCloseModal,
  onOpenModal,
  sendEmailBudget,
  cancelBudget,
  redirectToCreateNewBudget,
  budget,
  loading,
  duplicateBudgetInfo,
  redirectToBudgetList,
  loadingPdf,
}: ModalAlertProps) => {
  const { covenant_data } = budget ?? {};

  const history = useHistory();

  const [setCurrentModal, currentModal] = useBudgetOrder(useShallow((state) => [
    state.setCurrentModal,
    state.currentModal
  ]));

  const { setOrder: setOrderStore, setCurrentPaymentType } = useBudgetOrderLocalStorage()

  const [modals, setModals] = useState({
    completeLeadRegister: false,
    incompleteRegister: false,
  });

  const [booleans, setBooleans] = useState({
    disabledConvertButton: true
  })

  useEffect(() => {
    if (budget?.status) {
      setBooleans({
        ...booleans,
        disabledConvertButton: budget.status.id !== 0,
      });
    }
  }, [budget])

  useEffect(() => {

    if (currentModal === 'incompleteRegister') {
      setModals((prev) => ({ ...prev, incompleteRegister: true }));
    }
    if (currentModal === 'completeRegister') {
      setModals((prev) => ({ ...prev, completeLeadRegister: true }))
    }

  }, [currentModal])

  const handleCloseModal = (modal: string) => {
    setModals((prev) => ({ ...prev, [modal]: false }));
  }


  const changeBudgetStatus = async () => {
    try {
      await cancelBudget();
      onOpenModal("budgetCanceledSuccess");
    } catch (error) {
      console.error(error);
    }
  };

  const motionBudget = async (id: number | null) => {
    if (!id && budget?.is_covenant && budget.home_collected) {
      return createToast(
        "error",
        "Seleciona uma unidade para integrar ao orçamento"
      );
    }
    if (id && budget?.id) await motionIntegration(budget.id, id);

    if (budget?.id && budget?.unit)
      await motionIntegration(budget.id, budget.unit.id);
  };

  const handleUpdateCovenant = async ({
    covenant_id,
    covenant_plan_id,
    credential_number,
  }: CovenantSendParams) => {
    try {
      const personId = budget?.patient?.id;
      const credentialId = budget?.covenant_data?.credential_id;
      const credentials = {
        covenant_id,
        covenant_plan_id,
        credential_number,
      };

      if (credentialId) {
        await updateCovenantCredentials(personId, credentials, credentialId);
      }
    } catch (error) {
      throw new Error("Não foi possível atualizar dos dados do convênio");
    }
  };

  const handleUploadCovenant = async (frontCard: File, backCard: File) => {
    try {
      const personId = budget?.patient?.id;
      const credentialId = budget?.covenant_data?.credential_id;

      if (credentialId) {
        const formData = new FormData();
        formData.append("front_image", frontCard);
        formData.append("back_image", backCard);

        await CreateImgCovenant(formData, personId, credentialId);
      }
    } catch (error) {
      throw new Error(
        "Não foi possível fazer o upload dos dados da carteirinha"
      );
    }
  };

  const handleUploadMedicalOrder = async (medicalOrder: File) => {
    try {
      const formData = new FormData();
      const budgetId = budget?.id;
      formData.append("order", medicalOrder);

      if (budgetId) {
        await addGeneralInfoAndDocuments(budgetId, formData);
      }
    } catch (error) {
      throw new Error("Não foi possível fazer o upload do pedido médico");
    }
  };

  const onSubmit = async (values: BudgetMotion) => {
    try {
      const submitValues = values as BudgetMotionParams;

      const promises = [
        await handleUploadCovenant(
          submitValues.front_card,
          submitValues.back_card
        ),
        await handleUpdateCovenant({
          covenant_id: submitValues.covenant,
          covenant_plan_id: submitValues.plan,
          credential_number: submitValues.plan_card_number,
        }),
        await handleUploadMedicalOrder(submitValues.medical_order),
      ];

      await Promise.all(promises);

      onOpenModal("motionBudget");
    } catch (error: any) {
      createToast("error", error.message);
    }
  };

  const paragraphStyle: CSSProperties = {
    fontWeight: 400,
    fontSize: '1em',
    lineHeight: '1.6em',
    textTransform: 'uppercase',
    color: "#333333",
    textAlign: 'center'
  }

  const verifyPatientInfo = () => {
    if (budget) {

      const isLead = budget?.lead;
      setCurrentPaymentType([])

      
      if (isLead) {
        const leadOrderObject: OrderType = {
          budget_id: budget ? budget.id : 0,
          budget_type: budget ? budget.budget_type : 0,
          payment_info: [],
          appointment_type_id: "",
          isTaxInserted: false,
          payer_patient: {
            id: 0,
            birthdate: '',
            document: {
              id: 0,
              number: '',
              type: '',
              country_id: 0,
            },
            email: '',
            gender_id: 0,
            mother_name: '',
            name: '',
            phone: {
              id: 0,
              ddd: 0,
              ddi: 0,
              phone: 0
            },
            unborn: false,
            social_name: ''
          },
          lead: {
            id: budget.lead.id,
            ddd: budget.lead.ddd,
            email: budget.lead.email,
            name: budget.lead.name,
            phone: `+55${budget.lead.ddd}${budget.lead.phone}`
          },
          attendances: [],
          itemsQuantity: 0,
          totalPrice: budget.home_collect_value != 0 && budget.home_collect_value && !Number.isNaN(budget.home_collect_value) ? String(replaceStringNumber(Number(budget.total) - Number(budget.home_collect_value))) : budget.totalDisplay,
          homeCollectValue: 0,
          isCovenant: false,
          discounts: 0,
          subtotal: 0,
          deliveryTime: '',
          items: [],
          product_categories: [],
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 1,
            default: true,
            reference_point: '0',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: budget.coupon_code,
          home_collected: budget.home_collected,
          home_collect_value: '',
          coupon_percentage_discount: budget.coupon_percentage_discount,
          orderData: {
            order_type: 1,
            channel_id: 4,
            commercial_category: 63,
            appointment_type_id: "",
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              coupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        }

        setCurrentModal("incompleteRegister");
        setOrderStore(leadOrderObject)
      }

      if (!isLead) {
        const formattedAttendances = mountAttendances(budget.cart ?? []);

        const mountedItems = mountCartItems(budget.cart, budget)

        const patientOrderObject: OrderType = {
          budget_id: budget ? budget.id : 0,
          budget_type: budget ? budget.budget_type : 0,
          payment_info: [],
          appointment_type_id: "",
          isTaxInserted: false,
          lead: {
            id: 0,
            ddd: null,
            phone: '',
            email: null,
            name: '',
          },
          payer_patient: {
            id: budget.patient ? budget?.patient.id : 0,
            name: budget.patient ? budget.patient.name : '',
            birthdate: budget.patient ? budget?.patient.birthdate : '',
            document: {
              id: 0,
              type: budget.patient ? budget.patient.document.type : '',
              number: budget.patient ? budget.patient.document.number : '',
              country_id: 0,
            },
            email: budget.patient ? budget.patient.email : '',
            gender_id: budget.patient ? budget.patient.gender_id : 0,
            mother_name: budget.patient ? budget.patient.mother_name : '',
            phone: {
              id: budget.patient ? budget.patient.phone.id : 0,
              ddd: budget.patient ? budget.patient.phone.ddd : 0,
              ddi: budget.patient ? budget.patient.phone.ddi : 0,
              phone: budget.patient ? budget.patient.phone.phone : 0,
            },
            unborn: budget.patient ? budget.patient.unborn : false,
            social_name: budget.patient?.social_name ?? "",
          },
          homeCollectValue: 0,
          attendances: formattedAttendances,
          itemsQuantity: budget.cart.length ?? 0,
          totalPrice: budget.home_collect_value != 0 && budget.home_collect_value && !Number.isNaN(budget.home_collect_value) ? String(replaceStringNumber(Number(budget.total) - Number(budget.home_collect_value))) : budget.totalDisplay,
          isCovenant: budget.is_covenant,
          discounts: budget.discounts,
          subtotal: budget.subtotal,
          deliveryTime: budget.result_eta,
          items: mountedItems,
          product_categories: budget.product_categories,
          formattedAttendances: [],
          session_start: "",
          session_end: "",
          lastAddress: {
            street: "",
            number: "",
            uf: "",
            neighborhood: "",
            city: "",
            channel_id: 4,
            complement: '',
            country_id: 1,
            default: true,
            reference_point: '0',
            type_id: 1,
            zip_code: ''
          },
          attendanceLabel: [],
          dependents: [],
          patients: [],
          patientWithProducts: [],
          cupon_code: budget.coupon_code,
          home_collected: budget.home_collected,
          home_collect_value: '',
          coupon_percentage_discount: budget.coupon_percentage_discount,
          orderData: {
            order_type: 1,
            channel_id: 4,
            commercial_category: 63,
            appointment_type_id: "",
            commercial_document_number: '',
            payer_id: 0,
            order: {
              budget_id: 0,
              subtotal: '',
              discounts: '',
              total: 0,
              coupon_code: null,
              home_collected: false,
              home_collect_value: '',
              home_collect_date: '',
              vaccine_cards: [],
              cart: [],
              home_collect_schedule: {
                id_home_collected: 0,
                home_collect_date: "",
                zip_code: "",
                country: "Brasil",
                uf: "",
                city: "",
                neighborhood: "",
                street: "",
                number: "",
                complement: "",
                reference_point: "",
                address_type_id: 1,
              }
            }
          }
        }

        setOrderStore(patientOrderObject)
        history.push('/pedidos/criar-pedido/coleta')
      }

      onCloseModal("resendEmail")
    }
  }

  return (
    <>
      <Modal
        closeOverlay
        isOpen={modalOpen.resendEmail}
        requestClose={() => onCloseModal("resendEmail")}
        modalIcon={Success}
        renderHeading={() => <h2>Reenvio de orçamento</h2>}
        renderDescription={() => (
          <p>
            Selecione como você gostaria de reenviar esse orçamento ao cliente
            utilizando uma das opções abaixo:
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            {!budget?.is_covenant && (
              <Button
                onClick={() =>
                  CopyToClipboard({
                    message: "Copiado!",
                    text: budget?.site_link || "",
                  })
                }
                width="full"
              >
                Copiar link pagável do site!
              </Button>
            )}
            <SendEmailButton
              placeholder="Email do paciente"
              value={budget?.email ?? ""}
              label="Enviar por e-mail"
              submit={(patientEmail) =>
                sendEmailBudget(patientEmail, budget?.id)
              }
            />
            <Button
              onClick={() => handlePdfDownload(budget?.id)}
              width="full"
              outlined
              disabled={loadingPdf}
              loading={loadingPdf}
            >
              Baixar como PDF
            </Button>

            <p style={{ ...paragraphStyle, marginTop: '1em' }}>ou</p>
            <p style={{ ...paragraphStyle, textTransform: 'none', marginTop: '-1em' }}>O cliente quer já aprovou o orçamento e quer seguir com o pedido? Utilize as opções abaixo:</p>

            {!budget?.is_covenant ? <Button width="full" outlined onClick={() => verifyPatientInfo()} minHeight="3.7em" fontWeight="600" disabled={booleans.disabledConvertButton}>
              {" "}
              Converter em Pedido
            </Button> : <></>}

            <Button
              width="full"
              textButton
              onClick={() => onCloseModal("resendEmail")}
            >
              voltar para o orçamento
            </Button>
          </Box>
        )}
      />

      {modalOpen.motionBudget && (
        <MotionModal
          isOpen={modalOpen.motionBudget}
          closeModal={() => onCloseModal("motionBudget")}
          motionIntegration={motionBudget}
          showUnitsOptions={budget?.is_covenant && budget.home_collected}
        />
      )}

      {covenant_data && budget?.is_covenant && (
        <ModalCovenantMotion
          isOpen={modalOpen.motionCovenant}
          closeModal={() => onCloseModal("motionCovenant")}
          covenantSelected={{
            credential_number: covenant_data.covenant_plan_credential
              ? String(covenant_data.covenant_plan_credential)
              : null,
            covenant: {
              id: covenant_data.covenant_id,
              covenant: covenant_data.covenant_name,
            },
            covenant_plan: {
              id: covenant_data.covenant_plan_id,
              covenant_plans_name: covenant_data.covenant_plan_name,
              covenant_plans_code: covenant_data.covenant_plan_code,
            },
          }}
          onSubmit={onSubmit}
        />
      )}

      <MotionSuccessModal
        budgetId={budget?.id}
        closeModal={() => onCloseModal("motionBudgetSuccess")}
        isOpen={modalOpen.motionBudgetSuccess}
      />

      <Modal
        closeOverlay
        isOpen={modalOpen.cancelBudget}
        requestClose={() => onCloseModal("cancelBudget")}
        modalIcon={Success}
        renderHeading={() => <h2>Cancelar um orçamento salvo</h2>}
        renderDescription={() => (
          <p>
            <strong>Atenção:</strong> Ao cancelar este orçamento, iremos remover
            todas as menções deste orçamento para o cliente e não será mais
            possível utilizar o link de pagamento do site. Deseja prosseguir com
            o cancelamento?
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            <Button
              minHeight="48"
              width="full"
              onClick={changeBudgetStatus}
              loading={loading}
            >
              PROSSEGUIR COM CANCELAMENTO
            </Button>
            <Button
              minHeight="48"
              outlined
              width="full"
              onClick={() => onCloseModal("cancelBudget")}
            >
              VOLTAR PARA ORÇAMENTO
            </Button>
          </Box>
        )}
      />

      <Modal
        closeOverlay
        isOpen={modalOpen.budgetCanceledSuccess}
        requestClose={() => onCloseModal("budgetCanceledSuccess")}
        modalIcon={Success}
        renderHeading={() => <h2>Orçamento cancelado com sucesso</h2>}
        renderDescription={() => (
          <p>
            Este orçamento foi cancelado com sucesso e todas suas menções foram
            removidas da visualização para o cliente, no entanto você seguirá
            visualizando seu histórico no Colabi.
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            <Button minHeight="48" width="full" onClick={redirectToBudgetList}>
              VOLTAR PARA ORÇAMENTOS
            </Button>
            <Button
              minHeight="48"
              outlined
              width="full"
              onClick={redirectToCreateNewBudget}
            >
              CRIAR NOVO ORÇAMENTO
            </Button>
          </Box>
        )}
      />

      <Modal
        closeOverlay
        isOpen={modalOpen.editBudget}
        requestClose={() => onCloseModal("editBudget")}
        modalIcon={Success}
        renderHeading={() => <h2>Editar um orçamento salvo</h2>}
        renderDescription={() => (
          <p>
            A edição gerará um novo orçamento a partir deste orçamento salvo que
            você está visualizando. Deseja continuar?
          </p>
        )}
        renderActions={() => (
          <Box display="flex" direction="column">
            {" "}
            <Button
              minHeight="48"
              width="full"
              onClick={duplicateBudgetInfo}
              loading={loading}
              disabled={loading}
            >
              EDITAR COMO NOVO ORÇAMENTO
            </Button>
            <Button
              minHeight="48"
              outlined
              width="full"
              disabled={loading}
              onClick={() => onCloseModal("editBudget")}
            >
              CANCELAR
            </Button>
          </Box>
        )}
      />


      <CompleteLeadRegisterModal
        closeModal={() => handleCloseModal("completeLeadRegister")}
        isOpen={modals.completeLeadRegister}
        budget={budget}
      />

      <IncompleteRegisterModal
        closeModal={() => handleCloseModal("incompleteRegister")}
        isOpen={modals.incompleteRegister}
      />


    </>
  );
};
