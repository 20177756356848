import { Field, useFormikContext } from "formik"
import Input from "Components/Form/Input"
import { useBudgetOrderLocalStorage } from "Stores/budget-order"
import { PaymentOptionType } from "Pages/Orders/CreateOrder/components/PaymentTypes/types"
import InputCurrencyControlled from "Components/Form/Controlled/InputCurrencyControlled"
import { calculateAmount } from "Pages/Orders/CreateOrder/components/functions"
import { useEffect } from "react"
import { replaceStringNumber } from "Utils/ReplaceStringNumber"
import { PixTypeProps } from "./types"


export const PixType = ({ values, currentIndexPayment }: PixTypeProps) => {

  const { setFieldValue, errors, setFieldError, setErrors } = useFormikContext()

  const { order, setOrder, currentPaymentType } = useBudgetOrderLocalStorage();


  useEffect(() => {
    if (currentPaymentType[currentIndexPayment] === 'pix') {
      const clonnedPaymentInfo = [...order.payment_info];
      clonnedPaymentInfo[currentIndexPayment] = {
        ...order.payment_info[currentIndexPayment],
        pix: {
          ...values.pix,
          received: values.pix.received,
        }
      }

      setOrder({
        ...order,
        payment_info: clonnedPaymentInfo
      })
    }
  }, [values.pix.received])

  const handleChange = (e: React.ChangeEvent<any>) => {
    const { name, value } = e.target;

    setFieldValue(name, value);

    const clonnedPaymentInfo = [...order.payment_info];

    clonnedPaymentInfo[currentIndexPayment] = {
      ...order.payment_info[currentIndexPayment],
      pix: {
        ...values.pix,
        nsu: value
      }
    }

    setDataStorage(clonnedPaymentInfo)
  }

  const setDataStorage = (payment_info: PaymentOptionType[]) => {

    setOrder({
      ...order,
      payment_info
    });
  }

  const formatError = (name: string): string | boolean => {
    // Divide o nome em partes para acessar valores aninhados (ex: 'card.type' -> ['card', 'type'])
    const nameParts = name.split('.');

    // Função auxiliar para acessar valores aninhados em um objeto
    const getNestedValue = (obj: any, keys: string[]): any =>
      keys.reduce((acc, key) => (acc && typeof acc === 'object' ? acc[key] : undefined), obj);

    // Verifica e retorna o erro formatado ou `false` se não houver erro
    if (errors && typeof errors === 'object') {
      const nestedError = getNestedValue(errors, nameParts);
      return nestedError || false;
    }

    return false;
  };

  const handleReceivedBlur = () => {
    const total = (order.subtotal - order.discounts) + (order.homeCollectValue ? Number(replaceStringNumber(order.home_collect_value)) : 0);
    const verification = calculateAmount(currentPaymentType, order.payment_info, total);

    if (verification.isLowerTotal || verification.sameValue) {
      setFieldError('pix.received', '');
      const updatedErrors: any = { ...errors };
      delete updatedErrors?.money;
      return setErrors(updatedErrors);
    }
  }


  return (
    <>
      <div
        style={{ width: '100%', maxWidth: '100%', display: 'flex', flexDirection: 'row', margin: 10, gap: 10 }}
      >
        <div style={{ width: '33%' }}>
          <Field
            name="pix.received"
            id="pix.received"
            type="number"
            label="Valor"
            placeholder="Valor"
            value={values.pix.received}
            component={InputCurrencyControlled}
            error={formatError('pix.received')}
            onBlur={() => handleReceivedBlur()}
          />
        </div>
        <div style={{ width: '33%' }}>
          <Field
            name='pix.nsu'
            id='pix.nsu'
            label="NSU da Transação"
            type='number'
            component={Input}
            value={values.pix.nsu}
            onChange={handleChange}
            error={formatError('pix.nsu')}
          />
        </div>
      </div>
    </>
  )
}