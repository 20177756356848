import Select from "react-select";
import { CardFlagSelectProps, CardFlagType } from "./types";


export const CardFlagSelect = ({
  id,
  options,
  value,
  onChange,
  placeholder,
  error,
  label,
  loading
}: CardFlagSelectProps) => {

  const getValue = (options: CardFlagType[], value: string) => {
    return options.filter((o) => value === o.card_banner);
  }

  return (
    <div className={`
      field-holder 
      ${error ? "field-holder-invalid" : ""}
      `}>
      <label
        htmlFor={id}
        className='text-gray text-semibold _purple'
      >
        {label}
      </label>
      <Select
        menuPosition="fixed"
        classNamePrefix="react-select"
        className="react-select input _opacity-gray"
        id={id}
        value={getValue(options, value.card_banner)}
        onChange={(selectedOption) => {
          selectedOption ? onChange(selectedOption) : null
        }}
        options={options}
        placeholder={placeholder}
        isSearchable
        isLoading={loading}
        loadingMessage={() => 'Carregando...'}
        noOptionsMessage={() => "Nenhuma opção encontrada"}
        getOptionValue={(option) => option.card_banner}
        getOptionLabel={(option) => option.card_banner}
      />
    </div>
  )

}