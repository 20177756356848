import API from "Services/Api";


export const getReportLogs = async (code: string) => {

  try {
    const response = await API.get(`reports/log/${code}`);

    return response.data;
  } catch (error: any) {
    if (error.response.data) return [];
  }
}