import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

import moment from "moment";

import { GetDashData } from "Requests/Dashboard";

import { ContentPagesWrapper } from "layouts/ContentPagesWrapper/index";

import InputDateRange from "Components/Form/InputDateRange";

import { transformResponseToChartData } from "Utils/chart";

import { Container, Header, Charts, TopTen } from "./styles";

export const ResultsDashboard = () => {
  const initialDates = {
    dates: {
      startDate: moment().subtract(7, "days"),
      endDate: moment(),
    },
  };

  const [data, setData] = useState();
  const [dates, setDates] = useState(initialDates);

  const [selectedPeriod, setSelectedPeriod] = useState("days");

  const [daysSeries, setDaysSeries] = useState({
    labels: [],
    success: [],
    errors: [],
  });

  const [weeksSeries, setWeeksSeries] = useState({
    labels: [],
    success: [],
    errors: [],
  });

  const [monthsSeries, setMonthsSeries] = useState({
    labels: [],
    success: [],
    errors: [],
  });

  function handleDatesChange(value) {
    setDates({
      dates: value,
    });
  }

  async function getData(initialDate, finalDate) {
    const response = await GetDashData("results", initialDate, finalDate);

    setData(response);

    const { daysToChart, weeksToChart, monthsToChart } =
      transformResponseToChartData(response, "results", dates);

    setDaysSeries(daysToChart);
    setWeeksSeries(weeksToChart);
    setMonthsSeries(monthsToChart);
  }

  useEffect(() => {
    getData(dates.dates.startDate, dates.dates.endDate);
  }, []);

  const stackedOptions = {
    chart: {
      type: "bar",
      height: 350,
      stacked: true,
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 10,
      },
    },
    xaxis: {
      labels: {
        rotateAlways: true,
        rotate: -60,
        style: {
          fontSize: selectedPeriod === "weeks" ? "10.5px" : "11px",
        },
      },
      categories:
        selectedPeriod === "days"
          ? daysSeries.labels
          : selectedPeriod === "weeks"
          ? weeksSeries.labels
          : monthsSeries.labels,
    },
    legend: {
      position: "right",
      offsetY: 40,
    },
    fill: {
      opacity: 1,
    },
    series: [
      {
        name: "Integrados",
        data:
          selectedPeriod === "days"
            ? daysSeries.success
            : selectedPeriod === "weeks"
            ? weeksSeries.success
            : monthsSeries.success,
      },
      {
        name: "Não integrados",
        data:
          selectedPeriod === "days"
            ? daysSeries.errors
            : selectedPeriod === "weeks"
            ? weeksSeries.errors
            : monthsSeries.errors,
      },
    ],
    colors: ["#572580", "#F44336"],
  };

  function _tableList() {
    return !data?.top_errors?.length ? (
      <tr>
        <td colSpan="7" className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {data.top_errors?.map((error) => (
          <tr key={error.count}>
            <td>{error.count}</td>
            <td>{error.error}</td>
          </tr>
        ))}
      </>
    );
  }

  function _renderTable() {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>Quantidade</th>
            <th>Tipo de erro</th>
          </tr>
        </thead>
        <tbody>{_tableList()}</tbody>
      </table>
    );
  }

  return (
    <ContentPagesWrapper
      titleHeader="Dashboard"
      resizePaddignBottom
      listBreadcrumb={[
        {
          path: "/dashboard",
          label: "Dashboard",
          current: false,
        },
        {
          path: "/resultados",
          label: "resultados",
          current: true,
        },
      ]}
    >
      <Container>
        <h2>Laudos Recebidos do Motion</h2>
        <Header>
          <div>
            <span>
              Orverview da
              <br /> semana anterior
            </span>
          </div>
          <div>
            <div>
              <span>
                TOTAL DE
                <br /> PEDIDOS
                <br />
                {!data?.sent_rate.toString()
                  ? ""
                  : data?.sent_rate.toString().includes("-")
                  ? `↓ ${data?.sent_rate.toString()}`
                  : `↑ ${data?.sent_rate.toString()}`}
              </span>
              <p>{data?.sent_week.toString() || ""}</p>
            </div>
          </div>
          <div>
            <div>
              <span>
                TAXA DE
                <br /> SUCESSO
                <br />
                {!data?.rate_success_prev.toString()
                  ? ""
                  : data?.rate_success_prev.toString().includes("-")
                  ? `↓ ${data?.rate_success_prev.toString()}`
                  : `↑ ${data?.rate_success_prev.toString()}`}
              </span>
              <p>{data?.rate_success_week.toString() || ""}</p>
            </div>
          </div>
          <div>
            <div>
              <span>
                TOTAL DE
                <br /> ERROS
                <br />
                {!data?.errors_rate.toString()
                  ? ""
                  : data?.errors_rate.toString().includes("-")
                  ? `↓ ${data?.errors_rate.toString()}`
                  : `↑ ${data?.errors_rate.toString()}`}
              </span>
              <p>{data?.errors_week.toString() || ""}</p>
            </div>
          </div>
          <div>
            <div>
              <span>
                TAXA DE
                <br /> ERROS
                <br />
                {!data?.rate_errors_prev.toString()
                  ? ""
                  : data?.rate_errors_prev.toString().includes("-")
                  ? `↓ ${data?.rate_errors_prev.toString()}`
                  : `↑ ${data?.rate_errors_prev.toString()}`}
              </span>
              <p>{data?.rate_errors_week.toString() || ""}</p>
            </div>
          </div>
        </Header>
        <form className="form-heading">
          <div className="row">
            <InputDateRange
              startDatePlaceholderText="Data inicial"
              endDatePlaceholderText="Data final"
              startDateId="start_date"
              endDateId="end_date"
              onDatesChange={(value) => handleDatesChange(value)}
              openInModal={false}
              dates={dates.dates}
              blockPastChanges={false}
              withClear
            />

            <button
              className="button"
              type="button"
              onClick={() =>
                getData(dates.dates.startDate, dates.dates.endDate)
              }
            >
              {" "}
              Filtrar{" "}
            </button>
          </div>
          <div className="row">
            <button
              className={`button ${selectedPeriod === "days" && "active"}`}
              type="button"
              onClick={() => setSelectedPeriod("days")}
            >
              Dia
            </button>
            <button
              className={`button ${selectedPeriod === "weeks" && "active"}`}
              type="button"
              onClick={() => setSelectedPeriod("weeks")}
            >
              Semana
            </button>
            <button
              className={`button ${selectedPeriod === "months" && "active"}`}
              type="button"
              onClick={() => setSelectedPeriod("months")}
            >
              Mês
            </button>
          </div>
        </form>
        <Charts>
          <Chart
            options={stackedOptions}
            type="bar"
            series={stackedOptions.series}
            width="1000"
            height="300"
          />
        </Charts>
        <h2>Top 10 erros</h2>
        <TopTen>{_renderTable()}</TopTen>
      </Container>
    </ContentPagesWrapper>
  );
};
