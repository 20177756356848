import { Modal } from "Components/Modals/Modal";

import Success from "Assets/Images/successIcon.svg";
import { Box } from "styles/globalComponents";

import { docType } from "mocks";

import { Button } from "Components/Button";
import Input from "Components/Form/Input";
import { InputPhone } from "Components/Form/PhoneInput";
import Select from "Components/Form/Select";
import { ScrollToFieldError } from "Components/ScrollFiedError";

import { Formik, Form, Field, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { OrderType, useBudgetOrder, useBudgetOrderLocalStorage } from "Stores/budget-order";
import { useShallow } from "zustand/react/shallow";
import { useQuery } from "react-query";
import { Combos, createPerson } from "Requests/Person";

import * as Yup from "yup";
import { isWhitespaceOnly } from "Utils/String";
import { formatDateToBackend, isFutureDate, isValidDate } from "Utils/Date";
import { isValidPhoneMask } from "Utils/Phone";
import { isCPF } from "Services/schema/validations";
import { formatPhoneNumberIntl, Value } from "react-phone-number-input";
import { useHistory } from "react-router";
import { updatePersonBudget } from "Requests/UpdatePersonBudget";
import { Container } from "./styles";
import { CreatedPersonResponseType, FormLeadType, ObjectsType, SelectsType } from "./types";
import { mountAttendances } from "../Success/utils";


interface CompleteRegisterModalProps {
  isOpen: boolean;
  closeModal: () => void;
  budget: any | null
}

const combos = {
  countries: [],
  genders: [],
};

export const CompleteLeadRegisterModal = ({
  isOpen,
  closeModal,
  budget
}: CompleteRegisterModalProps) => {

  const history = useHistory();

  const [setCurrentModal] = useBudgetOrder(useShallow((state) => [
    state.setCurrentModal
  ]));

  const { order, setOrder: setOrderStore } = useBudgetOrderLocalStorage()

  const [objects, setObjects] = useState<ObjectsType>({
    lead: {
      id: 0,
      name: '',
      birth_date: '',
      ddd: 0,
      phone: '',
      document: '',
      document_type: '',
      email: '',
      gender: '',
      social_name: '',
      country: ''
    }
  });

  useEffect(() => {
    setObjects((objects) => ({
      ...objects,
      lead: {
        id: order.lead.id,
        name: order.lead.name,
        birth_date: '',
        ddd: 0,
        phone: order.lead.phone,
        document: '',
        document_type: '',
        email: order.lead.email ?? "",
        gender: '',
        social_name: '',
        country: ''
      }
    }))
  }, [order.lead])

  const { data = combos } = useQuery<SelectsType>(
    ["genders", "countries"],
    () =>
      Combos({
        combos: ["genders", "countries"],
      }),
    {
      refetchOnMount: false,
      refetchOnWindowFocus: false,
    }
  );

  const registerPersonSchema = Yup.object().shape({
    name: Yup.string().required("Nome obrigatório"),
    gender: Yup.string().required("Gênero é obrigatório"),
    social_name: Yup
      .string()
      .when("gender", {
        is: (gender: string) => gender === '2' || gender === '4' || gender === '5',
        then: Yup.string().required("Nome social obrigatório"),
        otherwise: Yup.string(),
      })
      .test("social_name", "Nome inválido", isWhitespaceOnly),
    birth_date: Yup
      .string()
      .required("Data de nascimento obrigatória")
      .test("birth_date", "Data inválida", (value) => isValidDate(value))
      .test("birth_date", "Data é maior que a atual", (value) =>
        isFutureDate(value)
      ),
    country: Yup.string().when("document_type", {
      is: (doctype: string) => doctype === "passport",
      then: Yup.string().required("Campo obrigatório"),
      otherwise: Yup.string().notRequired(),
    }),
    document_type: Yup.string().required("Informe o tipo de documento"),
    document: Yup.string().when("document_type", {
      is: (document_type: string) => document_type === "cpf",
      then: Yup.string().test("validateCpf", "CPF inválido", isCPF),
      otherwise: Yup.string().required("Documento é obrigatório"),
    }),
    email: Yup.string().email("Email inválido").required("Email é obrigatório"),
    phone: Yup
      .string()
      .required("Telefone obrigatório")
      .test("phone", "Telefone inválido", (value) => isValidPhoneMask(value)),
  });



  const handleChangeDocumentType = (
    e: React.ChangeEvent<any>,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => Promise<void | FormikErrors<FormLeadType>>) => {
    const currentDocType: string = e.target.value;
    setFieldValue('document_type', currentDocType)
  }

  const onSubmit = async (values: FormLeadType, setFieldError: (field: string, message: string) => void) => {

    if (budget) {
      const formattedPerson = formatValuesToSubmit(values);
      const formattedAttendances = mountAttendances(budget ? budget.cart : []);

      try {
        const createdPerson: CreatedPersonResponseType = await createPerson(formattedPerson);

        await updatePersonBudget(budget.id, createdPerson.id)


        const orderObject: OrderType = {
          ...order,
          payer_patient: {
            id: createdPerson.id,
            birthdate: createdPerson.birthdate,
            document: {
              id: 0,
              number: createdPerson.documents[0].number,
              type: createdPerson.documents[0].type,
              country_id: 0
            },
            email: createdPerson.emails[0].email,
            gender_id: createdPerson.gender.id,
            mother_name: '',
            name: createdPerson.full_name,
            phone: {
              id: createdPerson.phones[0].id,
              ddd: createdPerson.phones[0].ddd,
              ddi: createdPerson.phones[0].ddi,
              phone: createdPerson.phones[0].phone
            },
            unborn: false,
            social_name: createdPerson.social_name
          },
          lead: {
            id: 0,
            ddd: null,
            phone: '',
            email: null,
            name: '',
          },
          budget_id: budget ? budget.id : 0,
          homeCollectValue: budget.home_collect_value,
          attendances: formattedAttendances,
          itemsQuantity: budget.cart.length ?? 0,
          totalPrice: budget.totalDisplay ?? '0,00',
          isCovenant: budget.is_covenant,
          discounts: budget.discounts,
          subtotal: budget.subtotal,
          deliveryTime: budget.result_eta,
          items: budget.cart,
          product_categories: budget.product_categories,
          formattedAttendances: []
        }

        setOrderStore(orderObject)
        history.push('/pedidos/criar-pedido/coleta')
        setCurrentModal("")
      } catch (err: any) {
        console.error('Algo deu errado', err)
        if (err.response.data.errors) {
          err.response.data.errors.forEach((error: any) => {
            setFieldError(error.field, error.message);
          });
        }
      }
    }
  }

  const formatValuesToSubmit = (values: FormLeadType) => {

    const regex = /\+(\d+)\s+(\d+)\s+(\d+)\s+(\d+)/;

    const formattedPhone = formatPhoneNumberIntl(values.phone as Value);
    const splittedPhone = regex.exec(formattedPhone);

    const objectPerson = {
      isGhost: false,
      full_name: values.name,
      social_name: values.social_name ?? undefined,
      channel_id: 2,
      birthdate: formatDateToBackend(values.birth_date),
      gender_id: Number(values.gender),
      documents: [
        {
          number: values.document.replace(/\D/g, ""),
          ...(values.document_type === "passport" && {
            country_id: Number(values.country),
          }),
          ...(values.document_type === "passport" && {
            number: document,
          }),
          type: values.document_type,
        },
      ],
      emails: [
        {
          channel_id: 2,
          default: true,
          email: values.email ? values.email.toLowerCase() : "",
        },
      ],
      phones: [
        {
          default: true,
          channel_id: 2,
          ddi: 55,
          ddd: splittedPhone ? Number(splittedPhone[2]) : "",
          phone: splittedPhone ? Number(splittedPhone.slice(3).join("")) : "",
        },
      ],
    }

    return objectPerson
  }

  return (
    <Modal
      closeOverlay
      isOpen={isOpen}
      requestClose={closeModal}
      modalIcon={Success}
      maxHeight="800"
      renderHeading={() => (
        <h2>
          {" "}
          Completar cadastro
        </h2>
      )}
      renderDescription={() => (
        <p style={{ marginBottom: '-1.5em' }}>
          Preencha os campos abaixo para finalizar a criação do cadastro
          dessa pessoa e prosseguir com a conversão do orçamento em pedido:
        </p>
      )}
      renderActions={() => (
        <Container>
          <Formik
            initialValues={objects.lead}
            onSubmit={(values, { setFieldError }) =>
              onSubmit(values, setFieldError).then().catch()
            }
            validationSchema={registerPersonSchema}
          >
            {({ values, errors, touched, handleChange, setFieldValue, isSubmitting }) => (
              <Form className="form-container">
                <ScrollToFieldError />
                <div className="form-items">
                  <h3 style={{ marginBottom: '-0.1em' }}>DADOS DE IDENTIDADE</h3>

                  <div className="form-item">
                    <Field
                      name="name"
                      id="name"
                      label="NOME COMPLETO*"
                      component={Input}
                      value={values.name}
                      onChange={handleChange}
                      error={touched.name && errors.name && errors.name}
                      placeholder="Digite o nome completo"
                    />
                  </div>

                  <div className="form-item">
                    <Field
                      name="gender"
                      id="gender"
                      label="GÊNERO*"
                      options={data.genders}
                      value={values.gender}
                      optionName="sub_gender"
                      // readOnly={!validateArrayType(genders)}
                      onChange={handleChange}
                      component={Select}
                      placeholder="Selecione o gênero"
                      error={touched.gender && errors.gender && errors.gender}
                    />
                  </div>

                  <div className="form-item">
                    <Field
                      name="social_name"
                      id="social_name"
                      label="Como podemos te chamar?*"
                      component={Input}
                      value={values.social_name}
                      onChange={handleChange}
                      error={touched.social_name && errors.social_name && errors.social_name}
                      placeholder="Como gostaria de ser chamado"
                    />
                  </div>

                  <div className="form-item">
                    <Field
                      name="birth_date"
                      id="birth_date"
                      label="DATA DE NASCIMENTO*"
                      mask="99/99/9999"
                      component={Input}
                      value={values.birth_date}
                      onChange={handleChange}
                      error={
                        touched.birth_date && errors.birth_date && errors.birth_date
                      }
                      placeholder="01/10/1990"
                    />
                  </div>

                  <div className="form-item">
                    <Field
                      name="doc_type"
                      id="doc_type"
                      label="TIPO DE DOCUMENTO*"
                      options={docType}
                      value={values.document_type}
                      optionName="type"
                      onChange={(e: React.ChangeEvent<any>) =>
                        handleChangeDocumentType(e, setFieldValue)
                      }
                      component={Select}
                      placeholder="Selecione o tipo de documento"
                      error={
                        touched.document_type && errors.document_type && errors.document_type
                      }
                    />
                  </div>

                  {values.document_type === 'passport' ?
                    <div className="form-item">
                      <Field
                        name="country"
                        id="country"
                        label="PAÍS DE EMISSÃO*"
                        options={data.countries}
                        value={values.country}
                        optionName="name"
                        onChange={handleChange}
                        component={Select}
                        placeholder="Selecione o país de emissão"
                        error={
                          touched.country && errors.country && errors.country
                        }
                      />
                    </div>
                    : <></>}

                  <div className="form-item">
                    <Field
                      name="document"
                      id="document"
                      label="NÚMERO DE DOCUMENTO*"
                      component={Input}
                      mask={values.document_type === 'cpf' ? "999.999.999-99" : null}
                      value={values.document}
                      onChange={handleChange}
                      error={
                        touched.document && errors.document && errors.document
                      }
                      placeholder="Digite o número de documento"
                    />
                  </div>

                  <h3 style={{ marginBottom: '-0.1em' }}>Dados de Contato</h3>

                  <div className="form-item">
                    <Field
                      name="email"
                      id="email"
                      label="E-MAIL*"
                      type="email"
                      component={Input}
                      value={values.email}
                      onChange={handleChange}
                      error={touched.email && errors.email && errors.email}
                      placeholder="usuario@usuario.com.br"
                    />
                  </div>

                  <div className="form-item">
                    <Field
                      id="phone"
                      label="TELEFONE*"
                      placeholder="(11) 99999-1111"
                      component={InputPhone}
                      value={values.phone}
                      onChange={(phone: string) => setFieldValue("phone", phone)}
                      error={touched.phone && errors.phone && errors.phone}
                    />
                  </div>
                </div>

                <Box display="flex" direction="column" mt="16">
                  <Button
                    width="full"
                    minHeight="3.7em"
                    type="submit"
                    loading={isSubmitting}
                  >
                    SALVAR CADASTRO
                  </Button>

                  <Button
                    width="full"
                    minHeight="3.7em"
                    outlined
                    onClick={closeModal}
                    loading={isSubmitting}
                  >
                    CANCELAR
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Container>
      )}
    />
  )
}