import { formatBRLCurrency } from "Utils/FormatCurrency";
import moment from "moment";
import CreditsWithoutAccountImage from "Assets/Images/credit-without-account.svg";
import CreditsEmptyState from "Assets/Images/labi-creditos-empty-state.png";
import { CreditsData } from "./useDashBoard/interface";

interface LabiCreditsProps {
  creditsData: CreditsData | null;
}

export const LabiCredits = ({ creditsData }: LabiCreditsProps) => {
  const { credits, creditsMessage } = creditsData ?? {};

  function getCreditValue(value: string) {
    if (!value) return null;
    if (value === "None") return null;

    return value.replace("-", "");
  }

  return (
    <div className="dashboard-labi-credits">
      <span className="dashboard-sub-title">LABI CRÉDITOS</span>

      {credits ? (
        <div className="dashboard-credits-container">
          <div className="dashboard-credits-infos">
            <div>
              <span>CARTEIRA ATUAL</span>
              <p>
                R$ {formatBRLCurrency(getCreditValue(credits?.amount) ?? 0)}
              </p>
            </div>
            <div>
              <span>CRÉDITOS ACUMULADOS</span>
              <p>
                R${" "}
                {formatBRLCurrency(getCreditValue(credits?.accumulated) ?? 0)}
              </p>
            </div>
            <div>
              <span>CRÉDITOS GASTOS</span>
              <p>
                R$ {formatBRLCurrency(getCreditValue(credits?.spending) ?? 0)}
              </p>
            </div>
            <div>
              <span>NÚMERO DE INDICAÇÕES</span>
              <p>{credits?.indications ?? 0}</p>
            </div>
          </div>
          <div className="dashboard-credits-togo">
            <div>
              <span>CRÉDITOS A LIBERAR</span>
              <p>
                {credits?.to_available?.amount
                  ? `R$ ${formatBRLCurrency(
                      credits?.to_available?.amount
                    )} em ${moment(
                      new Date(credits?.to_available?.valid_at)
                    ).format("DD/MM/YYYY")}`
                  : "-"}
              </p>
            </div>
            <div>
              <span>CRÉDITOS A VENCER</span>
              <p>
                {credits?.to_expire?.amount
                  ? `R$ ${formatBRLCurrency(
                      credits?.to_expire?.amount
                    )} em ${moment(
                      new Date(credits?.to_expire?.expire_at)
                    ).format("DD/MM/YYYY")}`
                  : "-"}
              </p>
            </div>
            <div>
              <span>PEDIDOS COM LABI CRÉDITOS</span>
              <p>
                {credits?.to_final_order?.amount
                  ? `R$ ${formatBRLCurrency(
                      getCreditValue(credits?.to_final_order?.amount)
                    )} em ${moment(
                      new Date(credits?.to_final_order?.expire_at)
                    ).format("DD/MM/YYYY")}`
                  : "-"}
              </p>
            </div>
          </div>
        </div>
      ) : (
        <div className="empty">
          <img
            src={
              creditsMessage ===
              "Este cliente ainda não possui conta no site do Labi."
                ? CreditsWithoutAccountImage
                : CreditsEmptyState
            }
            alt="Labi Creditos"
          />

          <span>
            {creditsMessage ??
              "Este cliente ainda não utilizou o programa Labi Créditos!"}
          </span>
        </div>
      )}
    </div>
  );
};
