import { useState } from "react";
import { getReportLogs } from "Requests/ReportLog/index";
import DefaultProvider from "Components/DefaultProvider";
import LoadingImage from "Assets/Images/loading-purple.gif";
import moment from "moment";
import Input from "Components/Form/Input";
import { initialStateMessages } from "mocks";
import { ArraysType, BooleansType, BreadCrumbType, StateMessageType } from "./types";

const ReportLog = () => {

  const [booleans, setBooleans] = useState<BooleansType>({
    loading: false
  });

  const [arrays, setArrays] = useState<ArraysType>({
    logs: []
  });

  const [value, setValue] = useState<string>("")

  const [messages, setMessages] = useState<StateMessageType>(initialStateMessages);



  const fetchLogs = async (code: string) => {
    try {
      setBooleans((prevState) => ({
        ...prevState,
        loading: true
      }));

      const logs = await getReportLogs(code);

      setArrays((prevState) => ({
        ...prevState,
        logs
      }));

      setBooleans((prevState) => ({
        ...prevState,
        loading: false
      }));
    } catch (e) {
      console.error(e)
    }
  }

  const breadcrumb: BreadCrumbType[] = [
    {
      path: "/controle-visualizacao",
      label: "Laudos",
      current: false,
    },
    {
      path: "#",
      label: "Controle de Visualização",
      current: true,
    },
  ];

  const showLoading = () => {
    return (
      <tr>
        <td colSpan={5} className="text-center">
          <span className="loading-persons">
            <img src={LoadingImage} alt="" />
          </span>
        </td>
      </tr>
    );
  }

  const renderTable = () => {
    return (
      <table className="table-content small-separate details">
        <thead>
          <tr>
            <th>Código</th>
            <th>Data de Acesso</th>
          </tr>
        </thead>
        <tbody>
          {booleans.loading && showLoading()}
          {!booleans.loading && tableList()}
        </tbody>
      </table>
    );
  }

  const tableList = () => {
    return !arrays.logs.length ? (
      <tr>
        <td colSpan={5} className="text-center text-purple">
          Oops! Parece que nada foi encontrado...
        </td>
      </tr>
    ) : (
      <>
        {arrays.logs.map((log, index) => (
          <tr key={index}>
            <td>{log.requisition_code}</td>
            <td>{moment(log.created_at).utc(false).format('DD/MM/YYYY HH:mm:ss')}</td>
          </tr>
        ))}
      </>
    )
  }

  async function fetchClearFilters() {
    setValue("");
  }

  const searchLogs = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      if (!value)
        return setMessages({
          success: "",
          error: "Você deve passar um valor no campo de busca",
          wait: ""
        });


      if (value) {
        setMessages({
          success: "",
          error: "",
          wait: ""
        });
      }

      fetchLogs(value)
    } catch (error) {
      console.log(error);
    }
  };


  const renderMessages = ({ success, wait, error }: StateMessageType) => {
    if (error.length > 0)
      return <div className="box-message message-error">{error}</div>;

    if (wait.length > 0)
      return <div className="box-message message-wait">{wait}</div>;

    if (success.length > 0)
      return <div className="box-message message-success">{success}</div>;
  }

  return (
    <>
      <DefaultProvider
        pageHeaderTitle="Controle de Visualização"
        breadcrumb={breadcrumb}
        haveComponentOnHeader
        rightHeaderComponent={<></>}
      >
        <>
          <div className="order-home-filter mt-1">
            <form className="select-search-submit" onSubmit={searchLogs}>
              <Input
                id="code"
                description=""
                label=""
                error={null}
                mask={null}
                maskChar={null}
                maxLength="30"
                min="1"
                multiline={null}
                onBlur={null}
                onFocus={null}
                onKeyPress={null}
                purple={null}
                readOnly={null}
                required={null}
                small={null}
                type="text"
                autocomplete=""
                key="code"
                renderErrorComponent={undefined}
                placeholder="Código"
                value={value}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.value)}
              />
              <button className="button" type="submit">
                Buscar
              </button>
              <button className="button _white" onClick={fetchClearFilters}>
                Limpar
              </button>
            </form>
          </div>
          {renderMessages(messages)}
        </>
        <div className="page-content page-content-list">{renderTable()}</div>
      </DefaultProvider>
    </>
  )

}

export default ReportLog;