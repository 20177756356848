import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_MS_API_URL;

export const getCreditCardInstallments = async (order_value: number ) => {
  try {
    const data = await axios.get(`${BASE_URL}/installments?value=${order_value}`, {
      headers: {
        origin: process.env.REACT_APP_URL_COLABI,
        referer: process.env.REACT_APP_URL_COLABI
      }
    });

    return data.data
  } catch (error: any) {
    if (error.response) {
      const { status, data } = error.response;

      // Tratamento de erro 404
      if (status === 404) {
        toast.error(data.message);
      }

      // Tratamento de erro 500
      if (status === 500) {
        toast.error(
          "Ocorreu ao buscar as bandeiras do cartão."
        );
      }
    }
  }
}