import { replaceStringNumber } from "Utils/ReplaceStringNumber";
import { SelectPlotsProps } from "./types";


export const SelectPlots = ({
  selected,
  number_installments,
  installments,
  total,
  addition,
  onClick
}: SelectPlotsProps) => {

  return (
    <div onClick={onClick} className={`select-item-wrapper select-item-plot ${selected && "_plot-selected"
      }`}>
      <div className="info">
        <span className="text-gray text-bold">{number_installments}x</span>
        <span className="value text-gray">
          R$ {replaceStringNumber(installments[0].value)}
        </span>
        <span className="text-purple text-semibold text-opacity-50">
          {addition > 0 ? "Com juros" : "Sem juros"}
        </span>
      </div>
      <span className="text-lgray text-opacity-75">
        R$ {replaceStringNumber(total)}
      </span>
    </div>
  )

}